import React, { useState, useEffect } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar';
import '../../Users/All/AllUser.css';


export default function Rankedvideo() {
  const allrankedvideoApis = require('../../../apis/ranked_video');
  const [loading, setLoading] = useState(true);
  const [rankedvideo, setrankedvideo] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('')
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });


  // const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);


  const getAllrankedvideo = async (page, searchTerm = '') => {
    setLoading(true);
    try {
      const response = await allrankedvideoApis.getAllrankedvideo(page, searchTerm);
      const videoWithRemainingTime = response.payload.map(video => ({
        ...video,
        remainingTime: new Date(video.deleteAt) - Date.now()
      }));
      setrankedvideo(videoWithRemainingTime);
      console.log(response.payload, 'response.payload')
      setTotalPages(response.pagination.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching AllVideos:", error);
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   getAllrankedvideo();
  // }, []);




  useEffect(() => {
    getAllrankedvideo(currentPage, searchTerm);
  }, [currentPage, searchTerm]);






  // const otherapi = async () => {
  //   try {
  //     const result = await allrankedvideoApis.getVideo();

  //   } catch (error) {
  //     console.error("Error fetching AllVideos:", error);
  //   }
  // }


  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };


  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }
  };


  const handlePrevious = () => {
    if (currentPage > 1) {
      // setCurrentPage((prevPage) => prevPage - 1);
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });

    }
  };


  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };


  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };


  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };


  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };


  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };


  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };






  const handleDeleterankedvideo = async (id) => {
    setLoading(true);
    try {
      const response = await allrankedvideoApis.deleterankedvideo(id);


      if (response.success === true) {
        alert('Successfully  Delete ranked Video');
        getAllrankedvideo()


      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching AllVideos:", error);
      setLoading(false);
    }


  }




  // Function to show the video when a user clicks on the thumbnail
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [thumbnailClicked, setThumbnailClicked] = useState(false);


  const showVideo = (video) => {
    setSelectedVideo(video);
    setThumbnailClicked(true);
  };
  const closethumbModal = () => {
    setThumbnailClicked(false);
    setSelectedVideo(null);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setrankedvideo(rankvideo => rankvideo.map(video => {
        if (video.remainingTime <= 0) {
          return { ...video, remainingTime: 0 }; // Ensure time doesn't go negative
        }
        return {
          ...video,
          remainingTime: video.remainingTime - 1000
        };
      }));
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formatRemainingTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalHours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedrankVideo, setSelectedrankVideo] = useState(null);

  const handleChangeRank = (video) => {
    setSelectedrankVideo(video);
    setModalOpen(true);
  };

  const Changerank = async (id, rank) => {
    setLoading(true);
    try {
      const data = {
        updatedrank: rank
      }
      const response = await allrankedvideoApis.changerankvideo(id, data);


      if (response.success === true) {
        alert('Successfully  Change ranked Video');


      } else {
        alert(response.message);

      }
      getAllrankedvideo()

      setLoading(false);
    } catch (error) {
      console.error("Error fetching AllVideos:", error);
      setLoading(false);
    }


  }



  return (
    <IncludeSideBar>
      <div>
        <h2>Ranked All Video</h2>
        <input
          type="text"
          placeholder="Search by Rank And Video ID"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Video ID</th>
                <th>Thum</th>
                <th>User Name</th>
                <th>Rank</th>
                <th>Link</th>
                <th>Img</th>


                <th> Duration</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {rankedvideo.map((video, index) => {
                let linkwithimage=video.linkwithimg
                const date = new Date(video.deleteAt);
                const formattedDate = date.toLocaleDateString();
                const formattedTime = date.toLocaleTimeString();


                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{video.video_id}</td>
                    <td>
                      <img
                        src={`https://dpcst9y3un003.cloudfront.net/${video.videothumb}`}
                        alt="Thumbnail"


                        onClick={() => showVideo(video.video)}


                        style={{ cursor: 'pointer', width: '50px', height: '50px' }}
                      />
                    </td>
                    <td>{video.username}</td>
                    <td>{video.rank}</td>
                    <td>
                      <a href={video.addlink} target="_blank" rel="noopener noreferrer">
                        {video.addlink}
                      </a>
                    </td>
                    <td>
                      {linkwithimage?.map((item, index) => (
                      <div key={index} style={{ marginBottom: '10px' }}>
                        <a href={item.links} target="_blank" rel="noopener noreferrer">
                          <img src={`https://dpcst9y3un003.cloudfront.net/${item.Image}`} alt={`Link ${index}`} style={{ width: '50px', height: 'auto', borderRadius: '5px' }} />
                        </a>
                      </div>
                    ))}
                    </td>

                    <td>{formatRemainingTime(video.remainingTime)}</td>
                    <td>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        {/* <button onClick={() => handleDeleteVideo(video)}>Delete</button> */}
                        <button onClick={() => handleDeleterankedvideo(video.id)} >Delete</button>
                        <button onClick={() => handleChangeRank(video)}>Change Rank</button>

                        {/* <button onClick={() => otherapi()} >testing</button> */}




                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div className="pagination">
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            First
          </button>
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          {Array.from(
            { length: totalPages > pageWindow.end ? pageWindow.end : totalPages - pageWindow.start + 1 },
            (_, index) => pageWindow.start + index
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={currentPage === page ? 'active' : ''}
              style={{
                backgroundColor: currentPage === page ? 'red' : '',
                color: 'white',
              }}
            >
              {page}
            </button>
          ))}
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last
          </button>
        </div>






        {/* direct page */}
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
          <input
            type="number"
            value={inputPageNumber}
            onChange={(e) => setInputPageNumber(e.target.value)}
            placeholder="Page No"
            style={{
              padding: '5px',
              fontSize: '14px',
              // border: '1px solid #ccc',
              border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,


              borderRadius: '4px 0 0 4px',
              outline: 'none',
              width: '90px'
            }}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
          <button
            onClick={handleGoToPage}
            style={{
              padding: '6.5px 10px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderLeft: 'none',
              borderRadius: '0 4px 4px 0',
              // backgroundColor: '#007bff',

              backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
              color: 'white',
              cursor: 'pointer',
              outline: 'none',
            }}



          >
            Go
          </button>
        </div>


      </div>
      <Thumbnail
        showModal={thumbnailClicked}
        closeModal={closethumbModal}
        selectedVideo={selectedVideo}
      // loading={isLoading}


      />
      <ChangeRankModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={Changerank}
        currentRank={selectedrankVideo ? selectedrankVideo.rank : null}
        id={selectedrankVideo ? selectedrankVideo.id : null}
      />
    </IncludeSideBar>
  );
}




const Thumbnail = ({ showModal, closeModal, selectedVideo }) => {


  console.log(selectedVideo, 'selectedVideo')


  const modalStyle = {
    display: showModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    height: '500px',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.6)',


    borderRadius: '10px',
    padding: '20px'
  };


  const modalContentStyle = {
    // backgroundColor: '#fefefe',
    // width: '100%',
    // height: '100%',
    // borderRadius: '10px',
    // padding: '20px',
    // boxSizing: 'border-box',
    textAlign: 'center'
  };


  const closeButtonStyle = {
    color: '#fefefe',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer'
  };




  return (
    <div className="modal" style={modalStyle}>
      {/* <div className="modal-content" style={modalContentStyle}> */}
      <span className="close" style={closeButtonStyle} onClick={closeModal}>&times;</span>
      {selectedVideo && (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>


          <video controls width="220" >
            <source
              src={`https://dpcst9y3un003.cloudfront.net/${selectedVideo}`}
              type="video/mp4"
            />
          </video>
        </div>
      )}
      {/* </div> */}
    </div>
  );




}


const ChangeRankModal = ({ isOpen, onClose, onSave, currentRank, id }) => {
  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const modalContentStyle = {
    background: 'white',
    padding: '20px',
    borderRadius: '5px',
    width: '400px',
    maxWidth: '90%',
  };

  const buttonStyle = {
    marginRight: '10px',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  const saveButtonStyle = {
    ...buttonStyle,
    background: '#4CAF50',
    color: 'white',
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    background: '#f44336',
    color: 'white',
  };

  const [newRank, setNewRank] = useState(currentRank);
  // console.log(newRank,'video currentRank')
  useEffect(() => {
    setNewRank(currentRank);
  }, [currentRank, isOpen]);
  const handleSave = () => {
    onSave(id, newRank);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
        <h3>Change Rank</h3>

        <input
          type="number"
          value={newRank}
          onChange={(e) => setNewRank(e.target.value)}
        />
        <div>
          <button style={saveButtonStyle} onClick={handleSave}>Save</button>
          <button style={cancelButtonStyle} onClick={onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
