// import React, { useState, useEffect } from 'react';
// import './PushNotification.css';
// import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar'

// const AdminNotificationPanel = () => {
//   const AllNotificationUser = require('../../apis/notification')
//   const [title, setTitle] = useState('');
//   const [subtitle, setSubtitle] = useState('');
//   const [color, setColor] = useState('');
//   const [body, setBody] = useState('');
//   const [soundEnabled, setSoundEnabled] = useState(false);
//   const [vibrationEnabled, setVibrationEnabled] = useState(false);
//   const [importance, setImportance] = useState('DEFAULT');
//   const [iconFile, setIconFile] = useState(null);
//   const [pictureFile, setPictureFile] = useState(null);
//   const [selectedUsers, setSelectedUsers] = useState([]);
//   const [users, setUsers] = useState([]);
//   const [searchTerm, setSearchTerm] = useState('');
//   const [filteredUsers, setFilteredUsers] = useState([]);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [isFocused, setIsFocused] = useState(false)
//   const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
//   const [inputFocus, setInputFocus] = useState(false);
//   const [buttonFocus, setButtonFocus] = useState(false);
//   const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });

//   // const getUsers = async () => {
//   //   try {
//   //     const response = await AllNotificationUser.getNotificationUser();
//   //     setUsers(response.data);
//   //     setFilteredUsers(response.data);
//   //   } catch (error) {
//   //     console.error("Error fetching users:", error);
//   //   }
//   // };

//   // useEffect(() => {
//   //   getUsers();
//   // }, []);
//   const getUsers = async (page, searchTerm = '') => {
//     try {
//       const response = await AllNotificationUser.getNotificationUser(page, searchTerm);
//       setUsers(response.data);

//       setFilteredUsers(response.data)
//       setTotalPages(response.pagination.totalPages);
//       console.log(response.pagination.totalPages,'')
//       setFilteredUsers(response.data);
//     } catch (error) {
//       console.error("Error fetching users:", error);
//     }
//   };

//   useEffect(() => {
//     getUsers(currentPage, searchTerm);
//   }, [currentPage, searchTerm]);

//   // const handleSearch = (e) => {
//   //   const searchTerm = e.target.value;
//   //   setSearchTerm(searchTerm);

//   //   const filtered = basicUsers.filter(
//   //     (user) =>
//   //       user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
//   //       user.email.toLowerCase().includes(searchTerm.toLowerCase())
//   //   );

//   //   setFilteredUsers(filtered);
//   // };


//   const handleSearch = (event) => {
//     setSearchTerm(event.target.value);
//     const filtered = users.filter(
//       (user) =>
//         user.username.toLowerCase().includes(event.target.value.toLowerCase()) ||
//         user.email.toLowerCase().includes(event.target.value.toLowerCase())
//     );
//     setFilteredUsers(filtered);
//   };
//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };

//   const handleNext = () => {
//     if (currentPage < totalPages) {
//       setCurrentPage(currentPage + 1);
//       setPageWindow({
//         start: Math.floor((currentPage - 1) / 20) * 20 + 1,
//         end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
//       });
//     }

//   };

//   const handlePrevious = () => {
//     if (currentPage > 1) {
//       // setCurrentPage((prevPage) => prevPage - 1);
//       setCurrentPage(currentPage - 1);
//       setPageWindow({
//         start: Math.floor((currentPage - 1) / 20) * 20 + 1,
//         end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
//       });

//     }
//   };

//   const handleNextPageWindow = () => {
//     if (pageWindow.end < totalPages) {
//       setPageWindow({
//         start: pageWindow.start + 19,
//         end: Math.min(pageWindow.end + 19, totalPages),
//       });
//     }
//   };

//   const handlePreviousPageWindow = () => {
//     if (pageWindow.start > 1) {
//       setPageWindow({
//         start: pageWindow.start - 20,
//         end: pageWindow.end - 20,
//       });
//     }
//   };

//   const handlePageClick = (page) => {
//     handlePageChange(page);
//     if (page === pageWindow.end && pageWindow.end < totalPages) {
//       handleNextPageWindow();
//     } else if (page === pageWindow.start && pageWindow.start > 1) {
//       handlePreviousPageWindow();
//     }
//   };

//   const handleLastPage = () => {
//     setCurrentPage(totalPages);
//     setPageWindow({
//       start: Math.floor((totalPages - 1) / 20) * 20 + 1,
//       end: totalPages,
//     });
//   };

//   const handleFirstPage = () => {
//     setCurrentPage(1);
//     setPageWindow({
//       start: 1,
//       end: Math.min(20, totalPages),
//     });
//   };

//   const handleGoToPage = () => {
//     const pageNumber = parseInt(inputPageNumber, 10);
//     if (pageNumber >= 1 && pageNumber <= totalPages) {
//       setCurrentPage(pageNumber);
//       setPageWindow({
//         start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
//         end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
//       });
//       setInputPageNumber('');
//     } else {
//       alert(`Please enter a valid page number between 1 and ${totalPages}`);
//     }
//   };
//   const handleFocus = () => {
//     setIsFocused(true);
//   };
//   const handleBlur = () => {
//     setIsFocused(false);
//   };


//   const handleSelectAll = (event) => {
//     if (event.target.checked) {
//       const allUserIds = filteredUsers.map((user) => user.id);
//       setSelectedUsers(allUserIds);
//     } else {
//       setSelectedUsers([]);
//     }
//   };

//   const handleSelectFilteredUser = (userId) => {
//     if (!selectedUsers.includes(userId)) {
//       setSelectedUsers([...selectedUsers, userId]);
//     } else {
//       setSelectedUsers(selectedUsers.filter((id) => id !== userId));
//     }
//   };


//   const handleIconChange = (event) => {
//     console.log(event,"event")
//     const file = event.target.files[0];
//     console.log(file,"fileIcon")
//     if (file) {
//       setIconFile(file);
//     }
//   };

//   const handlePictureChange = (event) => {
//     const file = event.target.files[0];
//     console.log(file,"filePicture")
//     if (file) {
//       setPictureFile(file);
//     }
//   };
//   const handleImportanceChange = (event) => {
//     setImportance(event.target.value);
//   };


//   const sendNotificationToUsers = async (e) => {
//     e.preventDefault();
//     const formData = new FormData();
//     formData.append('title', title);
//     formData.append('subtitle', subtitle);
//     formData.append('body', body);
//     formData.append('color', color);
//     formData.append('sound_enabled', soundEnabled ? 'true' : 'false');
//     formData.append('vibration_enabled', vibrationEnabled ? 'true' : 'false');
//     formData.append('importance', importance);
//     selectedUsers.forEach((user) => {
//       console.log(user,"userId")
//       formData.append('id', user);
//     });
//     console.log(formData,"formData")

//     if (iconFile) {
//       formData.append('large_icon', iconFile);
//     }


//     if (pictureFile) {
//       formData.append('big_picture', pictureFile);
//     }

//     try {

//       const response = await AllNotificationUser.sendNotification(formData)
//       console.log(response)



//       // setTitle('');
//       // setSubtitle('');
//       // setColor('');
//       // setBody('');
//       // setSoundEnabled(false);
//       // setVibrationEnabled(false);
//       // setIconFile(null);
//       // setPictureFile(null);
//       // setSelectedUsers([]);

//       alert('Notification sent successfully!');

//     } catch (error) {
//       console.error('Error sending notification:', error);
//       alert('Failed to send notification');
//     }
//   };

//   return (
//     <IncludeSideBar> 
//     <div>
//       <h2>Admin Notification Panel</h2>
//       <div className="search-bar">
//           <input
//             type="text"
//             placeholder="Search by Username or Email"
//             value={searchTerm}
//             onChange={handleSearch}
//           />
//           {/* <button onClick={() => setSearchTerm('')}>Clear</button> */}
//         </div>
//         <div>
//           <h2>Select User</h2>
//           <table>
//             <thead>
//               <tr>
//                 <th>
//                   <input
//                     type="checkbox"
//                     onChange={handleSelectAll}
//                     checked={
//                       selectedUsers.length === filteredUsers.length &&
//                       filteredUsers.length > 0
//                     }
//                   />
//                   Select All
//                 </th>
//                 <th>ID</th>
//                 <th>Profile_pic</th>
//                 <th>Username</th>
//                 <th>Email</th>
//                 <th>account_type</th>
//               </tr>
//             </thead>
//             <tbody>
//               {filteredUsers.map((user) => (
//                 <tr key={user.id}>
//                   <td>
//                     <input
//                       type="checkbox"
//                       value={user.id}
//                       checked={selectedUsers.includes(user.id)}
//                       onChange={() => handleSelectFilteredUser(user.id)}
//                     />
//                   </td>
//                   <td>
//                     <img
//                       src={user.profile_pic}
//                       style={{
//                         width: "50px",
//                         height: "50px",
//                         borderRadius: "20%",
//                       }}
//                     />
//                   </td>
//                   <td>{user.id}</td>
//                   <td>{user.username}</td>
//                   <td>{user.email}</td>
//                   <td>{user.account_type}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//         <div className="pagination">
//           <button onClick={handleFirstPage} disabled={currentPage === 1}>
//             First
//           </button>
//           <button onClick={handlePrevious} disabled={currentPage === 1}>
//             Previous
//           </button>
//           {Array.from(
//             { length: pageWindow.end - pageWindow.start + 1 },
//             (_, index) => pageWindow.start + index
//           ).map((page) => (
//             <button
//               key={page}
//               onClick={() => handlePageClick(page)}
//               className={currentPage === page ? 'active' : ''}
//               style={{
//                 backgroundColor: currentPage === page ? 'red' : '',
//                 color: 'white',
//               }}
//             >
//               {page}
//             </button>
//           ))}
//           <button onClick={handleNext} disabled={currentPage === totalPages}>
//             Next
//           </button>
//           <button onClick={handleLastPage} disabled={currentPage === totalPages}>
//             Last
//           </button>
//         </div>



// {/* direct page */}
// <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
//                     <input
//                         type="number"
//                         value={inputPageNumber}
//                         onChange={(e) => setInputPageNumber(e.target.value)}
//                         placeholder="Page No"
//                         style={{
//                             padding: '5px',
//                             fontSize: '14px',
//                             // border: '1px solid #ccc',
//                             border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

//                             borderRadius: '4px 0 0 4px',
//                             outline: 'none',
//                             width: '90px'
//                         }}
//                         onFocus={() => setInputFocus(true)}
//                         onBlur={() => setInputFocus(false)}
//                     />
//                     <button
//                         onClick={handleGoToPage}
//                         style={{
//                             padding: '6.5px 10px',
//                             fontSize: '14px',
//                             border: '1px solid #ccc',
//                             borderLeft: 'none',
//                             borderRadius: '0 4px 4px 0',
//                             // backgroundColor: '#007bff',

//                         backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
//                         color: 'white',
//                     cursor: 'pointer',
//                     outline: 'none',
//                         }}


//                     >
//                     Go
//                 </button>
//             </div>


//       <form>
//         <div className="notification-message">
//           <label>Notification Message:</label>
//           {/* Your Editor or Text input for notification message */}
//         </div>
//         <div>
//           <label>Title:</label>
//           <input
//             type="text"
//             value={title}
//             onChange={(e) => setTitle(e.target.value)}
//             placeholder="Enter title"
//           />
//           <label>SubTitle:</label>
//           <input
//             type="text"
//             value={subtitle}
//             onChange={(e) => setSubtitle(e.target.value)}
//             placeholder="Enter subtitle"
//           />
//           <label>Color:</label>
//           <input
//             type="text"
//             value={color}
//             onChange={(e) => setColor(e.target.value)}
//             placeholder="Enter color"
//           />
//           <label>Body:</label>
//           <textarea
//             value={body}
//             onChange={(e) => setBody(e.target.value)}
//             placeholder="Enter body"
//           ></textarea>
//           <div>
//             <label>Importance:</label>
//             <select value={importance} onChange={handleImportanceChange}>
//               <option value="HIGH">High</option>
//               <option value="DEFAULT">Default</option>
//               <option value="LOW">Low</option>
//               <option value="MIN">Min</option>
//               <option value="NONE">None</option>
//             </select>
//           </div>


//           <label>Icon:</label>
//           <input
//             type="file"
//             onChange={handleIconChange}
//           />
//           <label>Picture:</label>
//           <input
//             type="file"
//             onChange={handlePictureChange}
//           />



//           <label>
//             <input
//               type="checkbox"
//               checked={soundEnabled}
//               onChange={(e) => setSoundEnabled(e.target.checked)}
//             />
//             Sound Enabled
//           </label>
//           <label>
//             <input
//               type="checkbox"
//               checked={vibrationEnabled}
//               onChange={(e) => setVibrationEnabled(e.target.checked)}
//             />
//             Vibration Enabled
//           </label>
//         </div>
//         <button
//           style={{ margin: "3%" }}
//           type="submit"
//           onClick={sendNotificationToUsers}
//         >
//           Send Notification
//         </button>
//       </form>
//     </div>
//     </IncludeSideBar>
//   );
// };

// export default AdminNotificationPanel;


import React, { useState, useEffect, useRef } from 'react';
import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's styles

const AdminNotificationPanel = () => {
  const AllNotificationUser = require('../../apis/notification');
  const [title, setTitle] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [color, setColor] = useState('');
  const [body, setBody] = useState('');
  const [picturelink, setpicturelink] = useState('')
  const [buttonlink, setbuttonlink] = useState('')
  const [buttontext, setbuttontext] = useState('')
  const [soundEnabled, setSoundEnabled] = useState(false);
  const [vibrationEnabled, setVibrationEnabled] = useState(false);
  const [importance, setImportance] = useState('DEFAULT');
  const [iconFile, setIconFile] = useState(null);
  const [pictureFile, setPictureFile] = useState(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [inputPageNumber, setInputPageNumber] = useState('');
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });
  const [allUserId, setAllUserId] = useState([]); // State for all user IDs
  const [selectedUserIds, setSelectedUserIds] = useState([]); // State for selected user IDs
  const [selectAll, setSelectAll] = useState(false); // State to manage the select all status
  const editableDivRef = useRef(null);
  const [showEmojiBox, setShowEmojiBox] = useState(false); // State to control the emoji box visibility
  const [selectedColor, setSelectedColor] = useState('#000000'); // State to manage selected text color
  const [fontSize, setFontSize] = useState(16); // State to manage font size


  const getUsers = async (page, searchTerm = '') => {
    try {
      const response = await AllNotificationUser.getNotificationUser(page, searchTerm);
      setUsers(response.data);
      setFilteredUsers(response.data);
      setTotalPages(response.pagination.totalPages);
      setAllUserId(response.selectalluserid); // Set the fetched all user IDs

    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    getUsers(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);

    const filtered = users.filter(
      (user) =>
        user.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        user.email.toLowerCase().includes(searchTerm.toLowerCase())
    );

    setFilteredUsers(filtered);
  };
  // const handleSearch = (searchValue) => {
  //   setSearchTerm(searchValue);
  // };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }
  };
  const handleInput = () => {
    setBody(editableDivRef.current.innerHTML); // Get the HTML content
  };

  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };

  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };

  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  // const handleSelectAll = (event) => {
  //   if (event.target.checked) {
  //     // const allUserIds = filteredUsers.map((user) => user.id);
  //     // setSelectedUsers(allUserIds);
  //     const selectedUserCount = 200;
  //     const allUserIds = allUserId.slice(0, selectedUserCount).map((user) => user.id);
  //     setSelectedUsers(allUserIds);

  //   } else {
  //     setSelectedUsers([]);
  //   }
  // };
  // Function to handle selecting all users across pages
  const handleSelectAll = (event, currentPage) => {
    const pageSize = 10; // Assuming each page shows 10 users
    const maxSelectCount = 150; // Maximum number of users to select
  
    if (event.target.checked) {
      // Collect user IDs starting from the current page
      const selectedUserIds = [];
      let count = 0;
  
      // Determine starting index based on currentPage
      // const startIndex = currentPage * pageSize; // Correctly set to start from the current page
      const startIndex = (currentPage - 1) * pageSize;

      // Iterate through the allUserId array to gather IDs up to maxSelectCount
      for (let i = startIndex; i < allUserId.length && count < maxSelectCount; i++) {
        selectedUserIds.push(allUserId[i].id);
        count++;
      }
  console.log(selectedUserIds,'selectedUserIdsselectedUserIds')
      // Set the selected user IDs state
      setSelectedUsers(selectedUserIds);
    } else {
      // Clear the selected users
      setSelectedUsers([]);
    }
  };
  
// Function to handle individual user selection


  const handleSelectFilteredUser = (userId) => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers([...selectedUsers, userId]);
    } else {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    }
  };



  const sendNotificationToUsers = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('subtitle', subtitle);
    formData.append('body', body);
    formData.append('color', color);
    formData.append('picturelink', picturelink);
    formData.append('buttonlink', buttonlink);
    formData.append('buttontext', buttontext);
    formData.append('sound_enabled', soundEnabled ? 'true' : 'false');
    formData.append('vibration_enabled', vibrationEnabled ? 'true' : 'false');
    formData.append('importance', importance);
    selectedUsers.forEach((user) => {
      formData.append('id', user);
    });

    if (iconFile) {
      formData.append('large_icon', iconFile);
    }

    if (pictureFile) {
      formData.append('big_picture', pictureFile);
    }

    try {
      const response = await AllNotificationUser.sendNotification(formData);
      alert('Notification sent successfully!');
    } catch (error) {
      console.error('Error sending notification:', error);
      alert('Failed to send notification');
    }
  };

  // Inline styles
  const styles = {
    container: {
      padding: '20px',
      backgroundColor: '#f8f9fa',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',

    },
    header: {
      fontSize: '24px',
      marginBottom: '20px',
      color: '#333',
    },
    searchBar: {
      marginBottom: '20px',
    },
    table: {
      // width: '90%',
      borderCollapse: 'collapse',
      marginBottom: '20px',
    },
    tableHeader: {
      backgroundColor: '#007bff',
      color: '#000',
    },
    tableCell: {
      padding: '5px',
      borderBottom: '1px solid #ddd',
      textAlign: 'left',
    },
    profilePic: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '10px',
    },
    paginationButton: {
      margin: '0 5px',
      padding: '8px 12px',
      borderRadius: '4px',
      border: '1px solid #ccc',
      cursor: 'pointer',
      backgroundColor: '#007bff',
      color: '#fff',
    },
    activeButton: {
      backgroundColor: '#0056b3',
    },
    formGroup: {
      marginBottom: '15px',
    },
    formLabel: {
      display: 'block',
      marginBottom: '5px',
      fontWeight: 'bold',
    },
    formInput: {
      width: '100%',
      padding: '8px',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    submitButton: {
      padding: '10px 20px',
      backgroundColor: '#28a745',
      border: 'none',
      borderRadius: '4px',
      color: '#fff',
      cursor: 'pointer',
    },
    editableDiv: {
      border: '1px solid #ccc',
      padding: '10px',
      minHeight: '100px',
      fontSize: '16px',
      outline: 'none',
      borderRadius: '5px',
      cursor: 'text',
    },


    formGroup: {
      marginBottom: '15px',
    },
    formLabel: {
      display: 'block',
      marginBottom: '5px',
      fontWeight: 'bold',
    },
    editableDiv: {
      border: '1px solid #ccc',
      padding: '10px',
      minHeight: '100px',
      fontSize: '16px',
      outline: 'none',
      borderRadius: '5px',
      cursor: 'text',
    },
    toolbar: {
      marginBottom: '10px',
      display: 'flex',
      gap: '5px',
    },
    button: {
      padding: '5px 10px',
      cursor: 'pointer',
    },
    emojiButton: {
      padding: '5px 10px',
      cursor: 'pointer',
    },
    emojiBox: {
      display: showEmojiBox ? 'flex' : 'none',
      flexWrap: 'wrap',
      gap: '5px',
      padding: '10px',
      border: '1px solid #ccc',
      borderRadius: '5px',
      background: '#fff',
      position: 'absolute',
      zIndex: 1000, // Ensure the emoji box is on top

      maxWidth: '200px',
      maxHeight: '150px',
      overflowY: 'scroll',
    },
    emojiItem: {
      fontSize: '18px',
      cursor: 'pointer',
      padding: '5px',
    },
  };


  // Function to apply color
  const changeTextColor = (color) => {
    document.execCommand('foreColor', false, color);
  };






  // Function to insert emoji
  const emojis = [
    '😊', '😂', '❤️', '👍', '🔥', '🥰', '😎', '🎉', '🤔', '🙌',
    '😢', '👏', '🥳', '😜', '😇', '😴', '🤗', '💪', '🙏', '😉',
    '😍', '😏', '🤩', '🤪', '🥺', '🤤', '🥶', '🥵', '🤯', '😱',
    '😬', '😳', '😡', '🤬', '😭', '🤮', '🥴', '🤢', '🥱', '😵',
    '🧐', '🤓', '🤥', '😶', '😷', '🤧', '🤒', '🤕', '🤠', '🤑',
    '🤫', '🤭', '😈', '👹', '👻', '👽', '👾', '🤖', '💀', '☠️',
    '🐶', '🐱', '🐭', '🐹', '🐰', '🦊', '🐻', '🐼', '🐨', '🐯',
    '🦁', '🐮', '🐷', '🐽', '🐸', '🐵', '🦄', '🐔', '🐧', '🐦',
    '🐤', '🐣', '🦆', '🐍', '🦎', '🐢', '🦖', '🦕', '🐙', '🦀',
    '🐟', '🐠', '🐡', '🦐', '🦑', '🐳', '🐋', '🐊', '🐆', '🦓',
  ];

  const insertEmoji = (emoji) => {
    const editableDiv = editableDivRef.current;
    setShowEmojiBox(false); // Close the emoji box after selecting

    if (editableDiv) {
      editableDiv.focus();

      // Get the current content of the editable div
      const currentContent = editableDiv.innerHTML;

      // Append the emoji to the current content
      editableDiv.innerHTML = currentContent + emoji;

      // Move the cursor to the end of the content
      const range = document.createRange();
      const selection = window.getSelection();
      range.selectNodeContents(editableDiv);
      range.collapse(false); // Collapse to the end of the content
      selection.removeAllRanges();
      selection.addRange(range);

      handlebodyInput(); // Update the state
    }
  };

  // Function to apply bold styling
  const applyBold = () => {
    document.execCommand('bold', false, null);
  };

  // Function to apply italic styling
  const applyItalic = () => {
    document.execCommand('italic', false, null);
  };
  const applyUnderline = () => {
    document.execCommand('underline');
  };

  // Function to apply highlight (background color)
  const applyHighlight = (color) => {
    document.execCommand('hiliteColor', false, color);
  };
  // Handle input changes in the contentEditable div
  const handlebodyInput = () => {
    const content = editableDivRef.current.innerHTML; // Get the HTML content
    setBody(content); // Update the state with HTML content
  };


  // Apply font size to all content in the editable div


  const handlePaste = (event) => {
    event.preventDefault();
    const text = event.clipboardData.getData('text/plain'); // Get plain text from clipboard
    document.execCommand('insertHTML', false, `<p>${text}</p>`); // Insert the text wrapped in <p>
    handlebodyInput(); // Capture updated content
  };
  return (
    <IncludeSideBar>
      <div style={styles.container}>
        <h2 style={styles.header}>Admin Notification Panel</h2>
        <div style={styles.searchBar}>
          <input
            type="text"
            placeholder="Search by Username or Email"
            value={searchTerm}
            onChange={handleSearch}
            style={styles.formInput}
          />
        </div>

        <table style={styles.table}>
          <thead style={styles.tableHeader}>
            <tr>
              <th style={styles.tableCell}>
                {/* <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  // checked={selectedUsers.length === filteredUsers.length}
                  checked={selectedUsers.length === Math.min(200, allUserId.length)}

                /> */}
                <input
                  type="checkbox"
                  onChange={(event) => handleSelectAll(event, currentPage)} // Pass the current page number
                  checked={selectedUsers.length > 0} // Indicate if there are selected users
                />
              </th>
              <th style={styles.tableCell}>Profile Picture</th>
              <th style={styles.tableCell}>ID</th>
              <th style={styles.tableCell}>Username</th>
              <th style={styles.tableCell}>Email</th>
              <th style={styles.tableCell}>account_type</th>

            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user) => (
              <tr key={user.id}>
                <td style={styles.tableCell}>
                  <input
                    type="checkbox"
                    checked={selectedUsers.includes(user.id)}
                    onChange={() => handleSelectFilteredUser(user.id)}
                    style={{
                      accentColor: selectedUsers.includes(user.id) ? "#4CAF50" : "", // Change checkbox color if selected
                    }}
                  />

                </td>
                <td style={styles.tableCell}>
                  <img
                    src={user.profile_pic}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "20%",
                    }}
                  />
                </td>
                <td style={styles.tableCell}>{user.id}</td>

                <td style={styles.tableCell}>{user.username}</td>
                <td style={styles.tableCell}>{user.email}</td>
                <td style={styles.tableCell}>{user.account_type}</td>

              </tr>
            ))}
          </tbody>
        </table>

        <div style={styles.pagination}>
          <button
            style={styles.paginationButton}
            onClick={handleFirstPage}
            disabled={currentPage === 1}
          >
            First
          </button>
          <button
            style={styles.paginationButton}
            onClick={handlePreviousPageWindow}
            disabled={pageWindow.start <= 1}
          >
            &lt;&lt;
          </button>
          {Array.from({ length: pageWindow.end - pageWindow.start + 1 }, (_, i) => (
            <button
              key={pageWindow.start + i}
              style={{
                ...styles.paginationButton,
                ...(currentPage === pageWindow.start + i
                  ? styles.activeButton
                  : {}),
              }}
              onClick={() => handlePageClick(pageWindow.start + i)}
            >
              {pageWindow.start + i}
            </button>
          ))}
          <button
            style={styles.paginationButton}
            onClick={handleNextPageWindow}
            disabled={pageWindow.end >= totalPages}
          >
            &gt;&gt;
          </button>
          <button
            style={styles.paginationButton}
            onClick={handleLastPage}
            disabled={currentPage === totalPages}
          >
            Last
          </button>
        </div>

        <form onSubmit={sendNotificationToUsers}>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Title:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Subtitle:</label>
            <input
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              style={styles.formInput}
            />
          </div>
          {/* <div style={styles.formGroup}>
            <label style={styles.formLabel}>Body:</label>
            <textarea
              value={body}
              onChange={(e) => setBody(e.target.value)}
              style={styles.formInput}
              rows={6}
            />
          </div> */}
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Body:</label>

            {/* Toolbar with emoji and formatting options */}
            <input
              type="color"
              value={selectedColor}
              onChange={(e) => {
                setSelectedColor(e.target.value); // Update selected color
                changeTextColor(e.target.value); // Apply the selected color
              }}
              style={styles.colorPicker}
              title="Select Text Color"
            />
            <div style={styles.toolbar}>
              <button
                type="button"
                style={styles.emojiButton}
                onClick={() => setShowEmojiBox(!showEmojiBox)}
                title="Add Emoji"
              >
                😊
              </button>
              <button type="button" style={styles.button} onClick={applyBold}>
                Bold
              </button>
              <button type="button" style={styles.button} onClick={applyItalic}>
                Italic
              </button>

              <button
                type="button"
                style={styles.button}
                onMouseDown={(e) => {
                  e.preventDefault(); // Prevent button from triggering form submit
                  applyUnderline();
                }}
                title="Underline Text"
              >
                Underline
              </button>

              {/* <button
          type="button"
          style={styles.button}
          onMouseDown={(e) => {
            e.preventDefault(); // Prevent button from triggering form submit
            applyHighlight('#ffff00'); // Yellow highlight
          }}
          title="Highlight Text"
        >
          Highlight
        </button> */}
              {/* Emoji Box */}
              <div

                style={styles.emojiBox}>
                {emojis.map((emoji, index) => (
                  <span
                    key={index}
                    style={styles.emojiItem}
                    onClick={() => insertEmoji(emoji)}
                  >
                    {emoji}
                  </span>
                ))}
              </div>
            </div>

            {/* ContentEditable Div for text input */}
            <div
              ref={editableDivRef}
              contentEditable
              style={{ ...styles.editableDiv, fontSize: `${fontSize}px` }} // Apply font size
              onInput={handlebodyInput} // Handle text input
              onPaste={handlePaste} // Handle pasting content
              placeholder="Type here..."
            />


            {/* Hidden Input for Submitting Form Data */}
            <textarea
              style={{ display: 'none' }}
              value={body}
              readOnly
              name="body"
            />
          </div>




          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Notification Color:</label>
            <input
              type="text"
              value={color}
              onChange={(e) => setColor(e.target.value)}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Notification Importance:</label>
            <select
              value={importance}
              onChange={(e) => setImportance(e.target.value)}
              style={styles.formInput}
            >
              <option value="DEFAULT">DEFAULT</option>
              <option value="HIGH">HIGH</option>
              <option value="LOW">LOW</option>
              <option value="MIN">MIN</option>
            </select>
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Notification Sound:</label>
            <input
              type="checkbox"
              checked={soundEnabled}
              onChange={(e) => setSoundEnabled(e.target.checked)}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Notification Vibration:</label>
            <input
              type="checkbox"
              checked={vibrationEnabled}
              onChange={(e) => setVibrationEnabled(e.target.checked)}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Large Icon:</label>
            <input
              type="file"
              onChange={(e) => setIconFile(e.target.files[0])}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Big Picture:</label>
            <input
              type="file"
              onChange={(e) => setPictureFile(e.target.files[0])}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Picture Link:</label>
            <input
              type="Picture Link"
              value={picturelink}
              onChange={(e) => setpicturelink(e.target.value)}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Button link:</label>
            <input
              type="button link"
              value={buttonlink}
              onChange={(e) => setbuttonlink(e.target.value)}
              style={styles.formInput}
            />
          </div>
          <div style={styles.formGroup}>
            <label style={styles.formLabel}>Button Text:</label>
            <input
              type="button Text"
              value={buttontext}
              onChange={(e) => setbuttontext(e.target.value)}
              style={styles.formInput}
            />
          </div>
          <button type="submit" style={styles.submitButton}>
            Send Notification
          </button>
        </form>
      </div>
    </IncludeSideBar>
  );
};

export default AdminNotificationPanel;
