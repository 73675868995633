import React, { useState } from 'react';
import SendGiftModal from './SendGiftModal.js';
import './AllUser.css'

const VideoModal = ({ showVideoModal, handleCloseVideoModal, videoData, setVideoData, handleViewVideo }) => {
    const allUserVideoApis = require("../../../apis/all_user_video");
    videoData = videoData || [];
    console.log(videoData, 'videoDatavideoDatavideoData')


    const [isEditingLikes, setIsEditingLikes] = useState(false);
    const [editedLikes, setEditedLikes] = useState(false);

    // const [isEditingDiamonds, setIsEditingDiamonds] = useState(false);
    // const [editedDiamonds, setEditedDiamonds] = useState(0);

    const [isEditingShared, setIsEditingShared] = useState(false);
    const [editedShared, setEditedShared] = useState(0);



    const [isEditingViewCount, setIsEditingViewCount] = useState(false);
    const [editedViewCount, setEditedViewCount] = useState(0);

    const [isDeletingVideoCount, setIsDeletingVideoCount] = useState(false);
    const [deletedVideoCount, setDeletedVideoCount] = useState(0);


    const [showSendGiftModal, setShowSendGiftModal] = useState(false);

    const openSendGiftModal = () => {
        setShowSendGiftModal(true);
    };

    const closeSendGiftModal = () => {
        setShowSendGiftModal(false);
    };



    const [isLoading, setIsLoading] = useState(false);

    // const handleEditDiamonds = (index, diamonds) => {
    //     setIsEditingDiamonds(index);
    //     setEditedDiamonds(diamonds);
    // };

    const handleEditLikes = (index, likes) => {
        setIsEditingLikes(index);
        setEditedLikes(likes);
    };
    const [ShowAddlikeModal, setShowAddlikeModal] = useState(false);
    const [CurrentlikeVideoIndex, setCurrentlikeVideoIndex] = useState(null);
    const [currentdeletelikeVideoIndex, setcurrentdeletelikeVideoIndex] = useState(null);
    const [showdeletelikeModal, setshowdeletelikeModal] = useState(false);
    const [CurrentcommentVideoIndex, setCurrentcommentVideoIndex] = useState(null)
    const [ShowAddcommentModal, setShowAddcommentModal] = useState(false)
    const [CurrentdeletecommentVideoIndex, setCurrentdeletecommentVideoIndex] = useState(null)
    const [ShowdeletecommentModal, setShowdeletecommentModal] = useState(false)
    const openAddlikeModal = (index) => {
        setCurrentlikeVideoIndex(index);
        setShowAddlikeModal(true);
    }
    const opendeletelikeModal = (videoid) => {
        setcurrentdeletelikeVideoIndex(videoid)
        setshowdeletelikeModal(true)
    }

    //comment

    const openAddcommentModal = (index) => {
        setCurrentcommentVideoIndex(index);
        setShowAddcommentModal(true);
    }

    const opendeletecommentModel = (videoid) => {
        setCurrentdeletecommentVideoIndex(videoid);
        setShowdeletecommentModal(true);

    }

    const handleaddcomment = async (addcomment) => {
        if (addcomment !== null) {
            try {
                setIsLoading(true);
                const updatedVideoData = [...videoData];
                // updatedVideoData[index].shared = editedShared;

                // Simulating an API call to update the shared count (replace this with the actual API call)
                await allUserVideoApis.updateVideocomment(CurrentcommentVideoIndex, addcomment);
                handleViewVideo(videoData[0].user_id)

                setVideoData(updatedVideoData);
                setIsLoading(false);
                alert(`Add ${addcomment} succsefully Comment `);

                setShowAddcommentModal(false)

            } catch (error) {
                setIsLoading(false);
                console.error('Error updating shared count:', error);
            }
        }
    };

    const handldeletecomment = async (deletecomment) => {
        if (deletecomment !== null) {
            try {
                setIsLoading(true);
                const updatedVideoData = [...videoData];
                // updatedVideoData[index].shared = editedShared;

                // Simulating an API call to update the shared count (replace this with the actual API call)
                await allUserVideoApis.deleteVideocomment(CurrentdeletecommentVideoIndex, deletecomment);
                handleViewVideo(videoData[0].user_id)

                setVideoData(updatedVideoData);
                setIsLoading(false);
                alert(`Deleted ${deletecomment}  Comment succsefully`);

                setShowdeletecommentModal(false)
            } catch (error) {
                setIsLoading(false);
                console.error('Error updating shared count:', error);
            }
        }
    }

    //end comment

    const handleSaveLikes = async (addlike) => {
        if (addlike !== null) {
            try {
                setIsLoading(true);
                const updatedVideoData = [...videoData];

                // Simulating an API call to update the likes count (replace this with actual API call)
                const response = await allUserVideoApis.updateLikeVideos(CurrentlikeVideoIndex, addlike);
                if (response.success) {
                    alert(`Add ${addlike} Like succsefully  & updated  `);
                    handleViewVideo(videoData[0].user_id)

                    setVideoData(updatedVideoData);
                    setShowAddlikeModal(false)
                    setIsLoading(false);

                }
                else {
                    alert(response.message);

                }
            } catch (error) {
                setIsLoading(false);
                console.error('Error updating like count:', error);
            }
        }
    };

    const handleDeletelikeVideoCount = async (deleteCount) => {
        try {
            setIsLoading(true);


            if (deleteCount === null) {
                alert(`please add Delete like greater than 0 `);

                return;
            }

            const parsedCount = parseInt(deleteCount);
            if (isNaN(parsedCount) || parsedCount <= 0) {
                throw new Error('Please enter a valid number greater than zero');
            }


            // Call the API to delete the specified count of videos
            await allUserVideoApis.deleteVideolikeCount(currentdeletelikeVideoIndex, parsedCount);
            handleViewVideo(videoData[0].user_id)

            // Update the count of videos that have been deleted
            setDeletedVideoCount(prevCount => prevCount + parsedCount);
            alert(`Like Succefully remove `);
            setshowdeletelikeModal(false)
            setIsLoading(false);


        } catch (error) {
        }
    };


    // const handleSaveDiamonds = async (index) => {
    //     if (editedDiamonds !== null && editedDiamonds !== videoData[index].diamond_value) {
    //         try {
    //             setIsLoading(true);
    //             const updatedVideoData = [...videoData];
    //             updatedVideoData[index].diamond_value = editedDiamonds;

    //             // Simulating an API call to update the diamonds count (replace this with the actual API call)
    //             await allUserVideoApis.updateDiamondVideos(videoData[index].id, editedDiamonds);

    //             setEditedDiamonds(0);
    //             setIsEditingDiamonds(false);
    //             setVideoData(updatedVideoData);
    //             setIsLoading(false);
    //         } catch (error) {
    //             setIsLoading(false);
    //             console.error('Error updating diamond count:', error);
    //         }
    //     }
    // };

    const handleEditShared = (index, shared) => {
        setIsEditingShared(index);
        setEditedShared(shared);
    };
    const [showAddshareModal, setShowAddshareModal] = useState(false);
    const [currentshareVideoIndex, setCurrentshareVideoIndex] = useState(null);
    const [showdeleteshareModal, setshowdeleteshareModal] = useState(false);
    const [currentdeleteshareVideoIndex, setcurrentdeleteshareVideoIndex] = useState(null);

    const openAddshareModal = (index) => {
        setCurrentshareVideoIndex(index);
        setShowAddshareModal(true);
    }

    const opendeletesharemodel = (videoid) => {
        setcurrentdeleteshareVideoIndex(videoid)
        setshowdeleteshareModal(true)

    }
    // Handler for saving edited shared count
    const handleSaveShared = async (addshare) => {
        if (addshare !== null) {
            try {
                setIsLoading(true);
                const updatedVideoData = [...videoData];
                // updatedVideoData[index].shared = editedShared;

                // Simulating an API call to update the shared count (replace this with the actual API call)
                await allUserVideoApis.updateVideoShared(currentshareVideoIndex, addshare);
                handleViewVideo(videoData[0].user_id)

                setEditedShared(0);
                setIsEditingShared(false);
                setVideoData(updatedVideoData);
                setIsLoading(false);
                alert(`Add succsefully shared}`);

                setShowAddshareModal(false)
            } catch (error) {
                setIsLoading(false);
                console.error('Error updating shared count:', error);
            }
        }
    };

    const handleDeleteshareVideoCount = async (deleteCount) => {
        try {
            setIsLoading(true);


            if (deleteCount === null) {
                alert(`please add share greater than 0 `);

                return;
            }

            const parsedCount = parseInt(deleteCount);
            if (isNaN(parsedCount) || parsedCount <= 0) {
                throw new Error('Please enter a valid number greater than zero');
            }


            // Call the API to delete the specified count of videos
            await allUserVideoApis.deleteVideoshareCount(currentdeleteshareVideoIndex, parsedCount);
            handleViewVideo(videoData[0].user_id)

            // Update the count of videos that have been deleted
            setDeletedVideoCount(prevCount => prevCount + parsedCount);
            alert(`Shared Succefully remove `);
            setshowdeleteshareModal(false)
            setIsLoading(false);


        } catch (error) {
        }
    };

    // // Handler for editing view count
    // const handleEditViewCount = (index, views) => {
    //     setIsEditingViewCount(index);
    //     setEditedViewCount(views);
    // };

    // // Handler for saving edited view count
    // const handleSaveViewCount = async (index) => {
    //     if (editedViewCount !== null && editedViewCount !== videoData[index].viewCount) {
    //         try {
    //             setIsLoading(true);
    //             const updatedVideoData = [...videoData];
    //             updatedVideoData[index].viewCount = editedViewCount;

    //             // Simulating an API call to update the view count (replace this with the actual API call)
    //             await allUserVideoApis.updateVideoCount(videoData[index].id, editedViewCount);

    //             setEditedViewCount(0);
    //             setIsEditingViewCount(false);
    //             setVideoData(updatedVideoData);
    //             setIsLoading(false);
    //         } catch (error) {
    //             setIsLoading(false);
    //             console.error('Error updating view count:', error);
    //         }
    //     }
    // };
    const [showAddViewsModal, setShowAddViewsModal] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(null);
    const [showdeleteViewsModal, setshowdeleteViewsModal] = useState(false);
    const [deletedvideoid, setdeletedvideoid] = useState(null)
    const openAddViewsModal = (index) => {
        setCurrentVideoIndex(index);
        setShowAddViewsModal(true);
    };
    const opendeletemodel = (videoid) => {
        setdeletedvideoid(videoid)
        setshowdeleteViewsModal(true)

    }
    const closeAddViewsModal = () => {
        setShowAddViewsModal(false);
        setshowdeleteViewsModal(false)
        setShowAddshareModal(false)
        setshowdeleteshareModal(false)
        setShowAddlikeModal(false)
        setshowdeletelikeModal(false)
        setShowAddcommentModal(false)
        setShowdeletecommentModal(false)
    };

    const handleAddViews = async (additionalViews) => {
        if (currentVideoIndex !== null && additionalViews > 0) {
            try {
                setIsLoading(true);

                const updatedVideoData = [...videoData];
                updatedVideoData[currentVideoIndex].viewCount += parseInt(additionalViews, 10);

                // Simulate API call to update the view count (replace with actual API call)
                await allUserVideoApis.updateVideoCount(videoData[currentVideoIndex].id, additionalViews);
                handleViewVideo(videoData[0].user_id)

                setVideoData(updatedVideoData);
                alert(`Total views are now ${updatedVideoData[currentVideoIndex].viewCount}`);
                setShowAddViewsModal(false);
                setIsLoading(false);


            } catch (error) {
                console.error('Error updating view count:', error);
            }
        }
    };

    const handleDeleteVideoCount = async (deleteCount) => {
        try {
            setIsDeletingVideoCount(true);
            setIsLoading(true);

            // const count = prompt('Enter the number of videos to delete:');

            if (deleteCount === null) {
                setIsDeletingVideoCount(false);
                console.log('Deletion cancelled by the user');
                return;
            }

            const parsedCount = parseInt(deleteCount);
            if (isNaN(parsedCount) || parsedCount <= 0) {
                throw new Error('Please enter a valid number greater than zero');
            }

            // Update the count of videos that are going to be deleted
            setDeletedVideoCount(parsedCount);

            // Call the API to delete the specified count of videos
            await allUserVideoApis.deleteVideoViewCount(deletedvideoid, parsedCount);
            handleViewVideo(videoData[0].user_id)

            // Update the count of videos that have been deleted
            setDeletedVideoCount(prevCount => prevCount + parsedCount);
            alert(`Views Succefully remove `);
            setshowdeleteViewsModal(false)
            setIsLoading(false);


            // Perform any actions after successful deletion
        } catch (error) {
            setIsDeletingVideoCount(false);
            console.error('Error deleting video count:', error);
            // Handle errors and show appropriate error messages
        }
    };





    const handleBlockVideo = async (index) => {
        // Ask for confirmation before blocking the video
        const confirmed = window.confirm("Are you sure you want to block this video?");

        if (confirmed) {
            try {
                const updatedVideoData = [...videoData];
                updatedVideoData[index].block = true; // Set the 'block' field to true

                // Simulating an API call to block the video (replace this with your actual API call)
                await allUserVideoApis.blockVideo(videoData[index].id);

                setVideoData(updatedVideoData);
            } catch (error) {
                console.error('Error blocking video:', error);
                // Implement error handling logic as needed
            }
        } else {
            // If the user cancels the action, you can perform any other action here or simply return
            console.log('Block action canceled by the user.');
            return;
        }
    };




    const modalStyle = {
        display: showVideoModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        margin: '10% auto',
        padding: '20px',
        border: '1px solid #888',
        width: '95%',
        top: 0,


    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold'
    };

    const tableStyle = {
        width: '95%',
        borderCollapse: 'collapse'
    };

    const thStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'center'
    };

    const tdStyle = {
        border: '1px solid #ddd',
        padding: '8px',
        textAlign: 'center'
    };

    const videoStyle = {
        width: '100px', // Set the desired width for the video
        height: '100px', // Set the desired height for the video
    };
    const loaderStyle = {
        border: '3px solid #f3f3f3',
        borderTop: '3px solid #3498db',
        borderRadius: '50%',
        width: '15px',
        height: '15px',
        animation: 'spin 1s linear infinite',
        marginLeft: '5px',
        display: isLoading ? 'block' : 'none',
    };
    const buttonStyle = {
        padding: '8px 12px',
        margin: '10 8px', // Added margin to create space between buttons
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        marginRight: '6px'
    };

    const deleteButtonStyle = {
        padding: '8px 12px',
        margin: '10 8px', // Added margin to create space between buttons
        background: 'red',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    };


    // Function to show the video when a user clicks on the thumbnail
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [thumbnailClicked, setThumbnailClicked] = useState(false);

    const showVideo = (video) => {
        setSelectedVideo(video);
        setThumbnailClicked(true);
    };
    const closethumbModal = () => {
        setThumbnailClicked(false);
        setSelectedVideo(null);
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={handleCloseVideoModal}>&times;</span>
                <h2>User Videos</h2>
                <table style={tableStyle}>
                    <thead>
                        <tr>
                            <th style={thStyle}>ID</th>
                            <th style={thStyle}>Video</th>
                            <th style={thStyle}>Comment</th>
                            <th style={thStyle}>Views</th>
                            <th style={thStyle}>Shared</th>
                            <th style={thStyle}>Diamonds</th>
                            <th style={thStyle}>likes</th>
                            {/* <th style={thStyle}>Action</th> */}
                            <th style={thStyle}>Gift_section</th>



                        </tr>
                    </thead>
                    <tbody>
                        {videoData.map((video, index) => (
                            <tr key={index}>
                                <td style={tdStyle}>{video.id}</td>
                                <td style={tdStyle}>

                                    <img
                                        src={`https://dpcst9y3un003.cloudfront.net/${video.thum}`}
                                        onClick={() => showVideo(video.video)}

                                        style={{
                                            width: "50px",
                                            height: "50px",
                                            borderRadius: "20%",
                                        }}

                                    />
                                </td>
                                <td style={tdStyle}>

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ marginRight: '12px' }}>
                                            {video.comments.length}
                                        </p>
                                        <button style={buttonStyle} onClick={() => openAddcommentModal(video.id)}>Add Comment</button>
                                        <button style={deleteButtonStyle} onClick={() => opendeletecommentModel(video.id)}>Delete</button>

                                    </div>
                                </td>

                                <td style={tdStyle}>
                                    {/* {isEditingViewCount === index ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="number"
                                                value={editedViewCount}
                                                onChange={(e) => setEditedViewCount(e.target.value)}
                                            />
                                            <button style={buttonStyle} onClick={() => handleSaveViewCount(index)}>Save</button>
                                            <button style={buttonStyle} onClick={() => handleEditViewCount(index, video.viewCount)}>Add</button>
                                            <button style={deleteButtonStyle} onClick={() => handleDeleteVideoCount(video.id)}>Delete</button>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <p style={{ marginRight: '12px' }}>{video.viewCount}</p>
                                            <button style={buttonStyle} onClick={() => handleEditViewCount(index, video.viewCount)}>Add</button>
                                            <button style={deleteButtonStyle} onClick={() => handleDeleteVideoCount(video.id)}>Delete</button>
                                        </div>
                                    )} */}
                                    <td style={tdStyle}>
                                        <div style={{ display: 'flex', alignItems: 'center', }}>
                                            <p style={{ marginRight: '12px' }}>{video.viewCount}</p>
                                            <button style={buttonStyle} onClick={() => openAddViewsModal(index)}>Add</button>
                                            {/* <button style={deleteButtonStyle} onClick={() => handleDeleteVideoCount(video.id)}>Delete</button> */}
                                            <button style={deleteButtonStyle} onClick={() => opendeletemodel(video.id)}>Delete</button>

                                        </div>
                                    </td>
                                </td>






                                <td style={tdStyle}>
                                    {/* {isEditingShared === index ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="number"
                                                value={editedShared}
                                                onChange={(e) => setEditedShared(e.target.value)}
                                            />
                                            <button style={buttonStyle} onClick={() => handleSaveShared(index)}>Save</button>
                                            {isLoading && <p style={loaderStyle}></p>}
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <p style={{ marginRight: '12px' }}>{video.shared}</p>
                                            <button style={buttonStyle} onClick={() => handleEditShared(index, video.shared)}>Add Share</button>
                                        </div>
                                    )} */}

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ marginRight: '12px' }}>{video.shareCount}</p>
                                        <button style={buttonStyle} onClick={() => openAddshareModal(video.id)}>Add share</button>
                                        {/* <button style={deleteButtonStyle} onClick={() => handleDeleteVideoCount(video.id)}>Delete</button> */}
                                        <button style={deleteButtonStyle} onClick={() => opendeletesharemodel(video.id)} >Delete</button>

                                    </div>
                                </td>

                                <td style={tdStyle}>
                                    {video.diamond_value}
                                </td>

                                <td style={tdStyle}>
                                    {/* {isEditingLikes === index ? (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <input
                                                type="number"
                                                value={editedLikes}
                                                onChange={(e) => setEditedLikes(e.target.value)}
                                            />
                                            <button style={buttonStyle} onClick={() => handleSaveLikes(index)}>Save</button>
                                            {isLoading && <p style={loaderStyle}></p>}
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <p style={{ marginRight: '12px' }}>{video.like}</p>
                                            <button style={buttonStyle} onClick={() => handleEditLikes(index, video.likes)}>Edit Likes</button>
                                        </div>
                                    )} */}

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <p style={{ marginRight: '12px' }}>{video.likeCount}</p>
                                        <button style={buttonStyle} onClick={() => openAddlikeModal(video.id)}>Add Like</button>
                                        {/* <button style={deleteButtonStyle} onClick={() => handleDeleteVideoCount(video.id)}>Delete</button> */}
                                        <button style={deleteButtonStyle} onClick={() => opendeletelikeModal(video.id)} >Delete</button>

                                    </div>

                                </td>
                                {/* <td style={tdStyle}>
                                    {video.block ? (
                                        <p>Blocked</p>
                                    ) : (
                                        <button style={buttonStyle} onClick={() => handleBlockVideo(index)}>Block</button>
                                    )}
                                </td> */}
                                <td style={tdStyle}>
                                    <button style={buttonStyle} onClick={openSendGiftModal}>Send Gift</button>
                                </td>

                                {/* Send Gift Modal */}
                                <SendGiftModal showSendGiftModal={showSendGiftModal} closeSendGiftModal={closeSendGiftModal} />



                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <AddViewsModal
                showAddViewsModal={showAddViewsModal}
                closeAddViewsModal={closeAddViewsModal}
                handleAddViews={handleAddViews}
                loading={isLoading}

            />
            <DeleteViewsModal
                showdeleteViewsModal={showdeleteViewsModal}
                closeAddViewsModal={closeAddViewsModal}
                handledeleteViews={handleDeleteVideoCount}
                loading={isLoading}

            />
            <Addshare
                showAddshareModal={showAddshareModal}
                closeAddViewsModal={closeAddViewsModal}
                handleEditShared={handleSaveShared}
                loading={isLoading}

            />
            <DeleteshareModal
                showdeleteshareModal={showdeleteshareModal}
                closeAddViewsModal={closeAddViewsModal}
                handledeleteShared={handleDeleteshareVideoCount}
                loading={isLoading}

            />
            <Addlike
                showaddlikeModal={ShowAddlikeModal}
                closeAddlikeModal={closeAddViewsModal}
                handleaddlike={handleSaveLikes}
                loading={isLoading}


            />
            <Deletelike
                showdeletelikeModal={showdeletelikeModal}
                closedeletelikeModal={closeAddViewsModal}
                handledeletelike={handleDeletelikeVideoCount}
                loading={isLoading}


            />
            <Addcomment

                showaddcommentModal={ShowAddcommentModal}
                closeaddcommmentModal={closeAddViewsModal}
                handleaddcomment={handleaddcomment}
                loading={isLoading}

            />
            <Deletecomment
                showdeletecommentModal={ShowdeletecommentModal}
                closedeletecommmentModal={closeAddViewsModal}
                handledeletecomment={handldeletecomment}
                loading={isLoading}
            />
            <Thumbnail
                showModal={thumbnailClicked}
                closeModal={closethumbModal}
                selectedVideo={selectedVideo}
                // loading={isLoading}

            />
        </div>
    );
};

export default VideoModal;



const AddViewsModal = ({ showAddViewsModal, closeAddViewsModal, handleAddViews, loading }) => {
    const [viewCount, setViewCount] = useState(0);

    const handleSubmit = () => {
        handleAddViews(viewCount);
    };

    const modalStyle = {
        display: showAddViewsModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const inputStyle = {
        width: '80%',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        padding: '8px 12px',
        margin: '10px 8px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={closeAddViewsModal}>&times;</span>
                <h2>Add Views</h2>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"> </div>
                    </div>
                )}
                <input
                    type="number"
                    value={viewCount}
                    onChange={(e) => setViewCount(e.target.value)}
                    style={inputStyle}
                />
                <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};


//deleting views

const DeleteViewsModal = ({ showdeleteViewsModal, closeAddViewsModal, handledeleteViews, loading }) => {
    const [deleteCount, setdeleteCount] = useState(0);

    const handleSubmit = () => {
        handledeleteViews(deleteCount);
    };

    const modalStyle = {
        display: showdeleteViewsModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const inputStyle = {
        width: '80%',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        padding: '8px 12px',
        margin: '10px 8px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={closeAddViewsModal}>&times;</span>
                <h2>Delete Views</h2>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"> </div>
                    </div>
                )}
                <input
                    type="number"
                    value={deleteCount}
                    onChange={(e) => setdeleteCount(e.target.value)}
                    style={inputStyle}
                />
                <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};


//add share

const Addshare = ({ showAddshareModal, closeAddViewsModal, handleEditShared, loading }) => {
    const [addshare, setaddshare] = useState(0);

    const handleSubmit = () => {
        handleEditShared(addshare);
    };

    const modalStyle = {
        display: showAddshareModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const inputStyle = {
        width: '80%',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        padding: '8px 12px',
        margin: '10px 8px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={closeAddViewsModal}>&times;</span>
                <h2>Add Share</h2>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"> </div>
                    </div>
                )}
                <input
                    type="number"
                    value={addshare}
                    onChange={(e) => setaddshare(e.target.value)}
                    style={inputStyle}
                />
                <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};


//delete share model
const DeleteshareModal = ({ showdeleteshareModal, closeAddViewsModal, handledeleteShared, loading }) => {
    const [deleteCount, setdeleteCount] = useState(0);

    const handleSubmit = () => {
        handledeleteShared(deleteCount);
    };

    const modalStyle = {
        display: showdeleteshareModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const inputStyle = {
        width: '80%',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        padding: '8px 12px',
        margin: '10px 8px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={closeAddViewsModal}>&times;</span>
                <h2>Delete Share</h2>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"> </div>
                    </div>
                )}
                <input
                    type="number"
                    value={deleteCount}
                    onChange={(e) => setdeleteCount(e.target.value)}
                    style={inputStyle}
                />
                <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};


//add like

const Addlike = ({ showaddlikeModal, closeAddlikeModal, handleaddlike, loading }) => {
    const [addlike, setaddlike] = useState(0);

    const handleSubmit = () => {
        handleaddlike(addlike);
        setaddlike(0)
    };

    const modalStyle = {
        display: showaddlikeModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const inputStyle = {
        width: '80%',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        padding: '8px 12px',
        margin: '10px 8px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={closeAddlikeModal}>&times;</span>
                <h2>Add Like</h2>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"> </div>
                    </div>
                )}
                <input
                    type="number"
                    value={addlike}
                    onChange={(e) => setaddlike(e.target.value)}
                    style={inputStyle}
                />
                <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};


//delete like

const Deletelike = ({ showdeletelikeModal, closedeletelikeModal, handledeletelike, loading }) => {
    const [deletelike, setdeletelike] = useState(0);

    const handleSubmit = () => {
        handledeletelike(deletelike);
        setdeletelike(0)
    };

    const modalStyle = {
        display: showdeletelikeModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const inputStyle = {
        width: '80%',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        padding: '8px 12px',
        margin: '10px 8px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={closedeletelikeModal}>&times;</span>
                <h2>Delete Like</h2>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"> </div>
                    </div>
                )}
                <input
                    type="number"
                    value={deletelike}
                    onChange={(e) => setdeletelike(e.target.value)}
                    style={inputStyle}
                />
                <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};

//add comment
const Addcomment = ({ showaddcommentModal, closeaddcommmentModal, handleaddcomment, loading }) => {
    const [addcomment, setaddcomment] = useState(0);

    const handleSubmit = () => {
        handleaddcomment(addcomment);
        setaddcomment(0)
    };

    const modalStyle = {
        display: showaddcommentModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const inputStyle = {
        width: '80%',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        padding: '8px 12px',
        margin: '10px 8px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={closeaddcommmentModal}>&times;</span>
                <h2>Add Comment</h2>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"> </div>
                    </div>
                )}
                <input
                    type="number"
                    value={addcomment}
                    onChange={(e) => setaddcomment(e.target.value)}
                    style={inputStyle}
                />
                <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};

//delete comment

const Deletecomment = ({ showdeletecommentModal, closedeletecommmentModal, handledeletecomment, loading }) => {
    const [deletecomment, setdeletecomment] = useState(0);

    const handleSubmit = () => {
        handledeletecomment(deletecomment);
        setdeletecomment(0)
    };

    const modalStyle = {
        display: showdeletecommentModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        height: '400px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.8)',
        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        backgroundColor: '#fefefe',
        width: '100%',
        height: '100%',
        borderRadius: '10px',
        padding: '20px',
        boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#aaa',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };

    const inputStyle = {
        width: '80%',
        padding: '10px',
        margin: '10px auto',
        borderRadius: '5px',
        border: '1px solid #ccc',
        boxSizing: 'border-box'
    };

    const buttonStyle = {
        padding: '8px 12px',
        margin: '10px 8px',
        background: 'blue',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer'
    };

    return (
        <div className="modal" style={modalStyle}>
            <div className="modal-content" style={modalContentStyle}>
                <span className="close" style={closeButtonStyle} onClick={closedeletecommmentModal}>&times;</span>
                <h2>Delete Comment</h2>
                {loading && (
                    <div className="loader-container">
                        <div className="loader"> </div>
                    </div>
                )}
                <input
                    type="number"
                    value={deletecomment}
                    onChange={(e) => setdeletecomment(e.target.value)}
                    style={inputStyle}
                />
                <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
            </div>
        </div>
    );
};


const Thumbnail = ({showModal,closeModal, selectedVideo}) => {

   console.log(selectedVideo,'selectedVideo')

    const modalStyle = {
        display: showModal ? 'block' : 'none',
        position: 'fixed',
        zIndex: 1,
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: '550px',
        height: '500px',
        overflow: 'auto',
        backgroundColor: 'rgba(0,0,0,0.6)',

        borderRadius: '10px',
        padding: '20px'
    };

    const modalContentStyle = {
        // backgroundColor: '#fefefe',
        // width: '100%',
        // height: '100%',
        // borderRadius: '10px',
        // padding: '20px',
        // boxSizing: 'border-box',
        textAlign: 'center'
    };

    const closeButtonStyle = {
        color: '#fefefe',
        float: 'right',
        fontSize: '28px',
        fontWeight: 'bold',
        cursor: 'pointer'
    };


    return (
        <div className="modal" style={modalStyle}>
            {/* <div className="modal-content" style={modalContentStyle}> */}
                <span className="close" style={closeButtonStyle} onClick={closeModal}>&times;</span>
                {selectedVideo && (
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop:'20px' }}>

                    <video controls width="220" >
                        <source
                            src={`https://dpcst9y3un003.cloudfront.net/${selectedVideo}`}
                            type="video/mp4"
                        />
                    </video>
                    </div>
                )}
            {/* </div> */}
        </div>
    );


}