import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'

function VideoDeletionRequest() {
    const allVideoApis = require('../../../apis/all_user_video');
    const [Deletiondata, setDeletiondata] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('')
    const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
    const [inputFocus, setInputFocus] = useState(false);
    const [buttonFocus, setButtonFocus] = useState(false);

    const getDeletionrequestdata = async (page, searchTerm = '') => {
        try {
            const response = await allVideoApis.getdeletionrequestdata(page, searchTerm)
            console.log(response.payload, "response from blocked users");
            setDeletiondata(response.payload);
            setTotalPages(response.pagination.totalPages);

        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    // useEffect(() => {
    //     getDeletionrequestdata();
    // }, []);

    useEffect(() => {
        getDeletionrequestdata(currentPage, searchTerm);
    }, [currentPage, searchTerm]);

    const handleSearch = (searchValue) => {
        setSearchTerm(searchValue);
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            // setCurrentPage((prevPage) => prevPage + 1);
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            // setCurrentPage((prevPage) => prevPage - 1);
            setCurrentPage(currentPage - 1);
        }
    };
    const handleGoToPage = () => {
        const pageNumber = parseInt(inputPageNumber, 10);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            setInputPageNumber('')
        } else {
            alert(`Please enter a valid page number between 1 and ${totalPages}`);
        }
    };

    const handleRollbackfordeletionaccount = async (video_id) => {
        console.log(video_id, 'user_iduser_iduser_id');
        setIsLoading(true);
        try {
            const response = await allVideoApis.Videodeltionrequest(video_id);
            console.log(response);
            alert("Successfully Roll Back for deletion Request");
            setDeletiondata(Deletiondata.filter(video => video.video_id !== video_id));
        } catch (error) {
            console.error("Error During deletion of video:", error);
            alert("Failed to delete the video");
        } finally {
            setIsLoading(false);
        }
    };

    const handledelet = (video_id) => {
        if (window.confirm(`Are you sure you want to Roll Back this Account`)) {
            handleRollbackfordeletionaccount(video_id);
        }
    }

    return (
        <IncludeSideBar>
            <div>
                <p> Video Deletion   Details Request:</p>
                <input
                    type="text"
                    placeholder="Search by user ID"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <table>
                    <thead>
                        <tr>
                            <th>S/N</th>
                            <th>Video ID</th>
                            <th>Thum</th>

                            <th>Deleted At</th>
                            <th>Action</th> {/* New column for the Status button */}
                        </tr>
                    </thead>
                    <tbody>
                        {Deletiondata.map((video, index) => {
                            const date = new Date(video.deleteAt);
                            const formattedDate = date.toLocaleDateString(); // Example: "5/27/2024"
                            const formattedTime = date.toLocaleTimeString(); // Example: "3:29:27 PM"

                            return (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{video.video_id}</td>
                                    <td>
                                        <img
                                            src={`https://dpcst9y3un003.cloudfront.net/${video.thum}`}
                                            alt="Thumbnail"
                                            // onClick={() => showVideo(video)}
                                            style={{ cursor: 'pointer', width: '50px', height: '50px' }}
                                        />
                                    </td>

                                    <td>{formattedDate} {formattedTime}</td>
                                    <td>

                                        {/* <button onClick={()=>handleRollbackfordeletionaccount(user.user_id)}>
                                            Roll Back Deletion Account
                                        </button> */}
                                        <button onClick={() => handledelet(video.video_id)}>
                                            Roll Back Deletion Video
                                        </button>

                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
                <div className="pagination">
                    <button onClick={handlePrevious}>Previous</button>
                    {Array.from({ length: Math.min(totalPages, 20) }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? "active" : ""}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button onClick={handleNext}>Next</button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                    <input
                        type="number"
                        value={inputPageNumber}
                        onChange={(e) => setInputPageNumber(e.target.value)}
                        placeholder="Page No"
                        style={{
                            padding: '5px',
                            fontSize: '14px',
                            // border: '1px solid #ccc',
                            border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                            borderRadius: '4px 0 0 4px',
                            outline: 'none',
                            width: '90px'
                        }}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    <button
                        onClick={handleGoToPage}
                        style={{
                            padding: '6.5px 10px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderLeft: 'none',
                            borderRadius: '0 4px 4px 0',
                            // backgroundColor: '#007bff',
                            
                        backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                        color: 'white',
                    cursor: 'pointer',
                    outline: 'none',
                        }}

                   
                    >
                    Go
                </button>
            </div>



            </div>

        </IncludeSideBar >
    );
}

export default VideoDeletionRequest;
