// EmailNotification.js
import React, { useState } from 'react';
import IncludeSideBar from '../../Components/Sidebar/IncludeSideBar';
// import ReactDOMServer from 'react-dom/server';
// import EmailTemplate from './EmailTemplate'; // Adjust path as per your folder structure

const EmailNotification = () => {
    const [emailList, setEmailList] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [links, setLinks] = useState([{ url: '', text: '' }]);
    const [logoUrl, setLogoUrl] = useState('');
    const [imageUrl, setImageUrl] = useState('');
    const [searchTerm, setSearchTerm] = useState('');

    // Handle input change for adding multiple links
    const handleLinkChange = (index, field, value) => {
      const newLinks = [...links];
      newLinks[index][field] = value;
      setLinks(newLinks);
    };
  
    // Add a new link input field
    const addLinkField = () => {
      setLinks([...links, { url: '', text: '' }]);
    };
  
    // Remove a link input field
    const removeLinkField = (index) => {
      setLinks(links.filter((_, i) => i !== index));
    };
  
    // Handle logo upload
    const handleLogoUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => setLogoUrl(reader.result);
        reader.readAsDataURL(file);
      }
    };
  
    // Handle image upload inside email content
    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => setImageUrl(reader.result);
        reader.readAsDataURL(file);
      }
    };
  
  
const styles = {
    formInput: {
        width: '100%',
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
      },
}
  return (
    <IncludeSideBar>
      <div>
        <h2 style={{ textAlign: 'center', color: 'red' }}>Email Send</h2>
        <div style={styles.searchBar}>
          <input
            type="text"
            placeholder="Search by Username or Email"
            value={searchTerm}
            // onChange={handleSearch}
            style={styles.formInput}
          />
        </div>
        <form style={{ maxWidth: '600px', margin: '0 auto' }}>
          {/* <div style={{ marginBottom: '10px' }}>
            <label>Email Addresses (comma-separated):</label>
            <input
              type="text"
              value={emailList}
              onChange={(e) => setEmailList(e.target.value)}
              placeholder="user1@example.com, user2@example.com"
              style={{ width: '100%', padding: '8px' }}
            />
          </div> */}
          <div style={{ marginBottom: '10px' }}>
            <label>Title :</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              placeholder="Email Subject"
              style={{ width: '100%', padding: '8px' }}
            />
          </div>
          <div style={{ marginBottom: '10px' }}>
            <label>Message:</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your message here..."
              style={{ width: '100%', padding: '8px' }}
            />
          </div>

          {/* Upload Logo */}
          <div style={{ marginBottom: '10px' }}>
            <label>Upload Logo:</label>
            <input type="file" accept="image/*" onChange={handleLogoUpload} />
          </div>

          {/* Upload Image */}
          <div style={{ marginBottom: '10px' }}>
            <label>Upload Image in Content:</label>
            <input type="file" accept="image/*" onChange={handleImageUpload} />
          </div>

          {/* Render link input fields */}
          {links.map((link, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <input
                type="text"
                placeholder="Link URL"
                value={link.url}
                onChange={(e) => handleLinkChange(index, 'url', e.target.value)}
                style={{ marginRight: '10px', padding: '8px' }}
              />
              {/* <input
                type="text"
                placeholder="Link Text"
                value={link.text}
                onChange={(e) => handleLinkChange(index, 'text', e.target.value)}
                style={{ marginRight: '10px', padding: '8px' }}
              /> */}
              <button type="button" onClick={() => removeLinkField(index)} style={{ color: '#fff' }}>
                Remove
              </button>
            </div>
          ))}

          <button type="button" onClick={addLinkField} style={{ marginBottom: '10px' }}>
            Add More Links
          </button>

          <button type="button"  style={{ padding: '10px 20px', backgroundColor: 'blue', color: 'white' }}>
            Send Email
          </button>
        </form>
        <EmailTempl
         logoUrl={logoUrl}
         heading={subject}
         message={message}
         imageUrl={imageUrl}
         links={links}
        />
      </div>
    </IncludeSideBar>
  );
};

export default EmailNotification;



// Email Template Component
const EmailTempl = ({ logoUrl, heading, message, links, imageUrl }) => {
    console.log(links,'linkslinks')
  return (
    <div style={styles.container}>
      {/* Logo Section */}
      <div style={styles.headerContainer}>
        {/* Logo Section */}
        {logoUrl && (
          <div style={styles.logoContainer}>
            <img src={logoUrl} alt="Logo" style={styles.logo} />
          </div>
        )}

        {/* Heading Section */}
        <h1 style={styles.heading}>{heading}</h1>
      </div>

      {/* Message Section */}
      <p style={styles.message}>{message}</p>

      {/* Image Section */}
      {imageUrl && (
        <div style={styles.imageContainer}>
          <img src={imageUrl} alt="Content Image" style={styles.image} />
        </div>
      )}

      {/* Links Section */}
      {links.length > 0 && (
        <ul style={styles.linksContainer}>
          {links.map((link, index) => (
            <li key={index} style={styles.linkItem}>
              <a href={link.url} style={styles.link}>
                {link.url}
              </a>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

// CSS Styles for the Email Template
const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ddd',
    borderRadius: '8px',
    backgroundColor: '#f9f9f9',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center', // Adjust alignment as needed
    marginBottom: '10px',
    // backgroundColor:'red'
  },
  logoContainer: {
    // Ensure the logo container doesn’t interfere with heading alignment
    marginRight: 'auto', // Pushes heading to the center
  },
  logo: {
    maxWidth: '90px',
    height: 'auto',
    borderRadius:50
  },
  heading: {
    flex: 1, // Allows heading to take up remaining space
    textAlign: 'center',
    color: '#333',
    marginRight:'90px',
    margin: '0', // Remove default margin for better alignment
  },
  message: {
    fontSize: '16px',
    lineHeight: '1.6',
    color: '#555',
    marginBottom: '20px',
  },
  imageContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  image: {
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
  },
  linksContainer: {
    padding: '0',
    listStyleType: 'none',
  },
  linkItem: {
    marginBottom: '10px',
  },
  link: {
    color: '#1a73e8',
    textDecoration: 'none',
  },
};

