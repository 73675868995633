import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import React, { useEffect, useState } from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa'; // Import FontAwesome icons

import useAuth from "../../../useAuth";

function VerificationRequest() {
  const { user } = useAuth();
  const role = user ? user.role : null;
  const allUserApis = require('../../../apis/users');
  const alluserverificationrequestapi = require('../../../apis/verification_request_by_user');

  const [Verificationdetail, setVerificationdetail] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [disapprovalSubject, setDisapprovalSubject] = useState('');
  const [disapprovalReason, setDisapprovalReason] = useState('');
  const [userdetail, setuserdetail] = useState([])
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [interactionData, setInteractionData] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('lastDay');
  const [document, setdocument] = useState([])

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchTerm, setSearchTerm] = useState('')
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);
  const [badgeTypeDropdownVisibility, setbadgeTypeDropdownVisibility] = useState({});
  const [badgeTypes, setbadgeTypes] = useState({});

// aprove with badge

const [isVerificationModalOpen,setisVerificationModalOpen]=useState(false)
const [ aprovedUserId,setaprovedUserId]=useState(null)
  const getuserverificationdetail = async (page, searchTerm = '') => {
    try {
      const response = await allUserApis.getallvirificationrequest(page, searchTerm)
      console.log(response.data, "response from blocked users");
      setVerificationdetail(response.payload)
      setdocument(response.data)
      setTotalPages(response.pagination.totalPages);

    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  // useEffect(() => {
  //   getuserverificationdetail();
  // }, []);


  useEffect(() => {
    getuserverificationdetail(currentPage, searchTerm);
}, [currentPage, searchTerm]);

const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
};


const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
};

const handleNext = () => {
    if (currentPage < totalPages) {
        // setCurrentPage((prevPage) => prevPage + 1);
        setCurrentPage(currentPage + 1);
    }
};

const handlePrevious = () => {
    if (currentPage > 1) {
        // setCurrentPage((prevPage) => prevPage - 1);
        setCurrentPage(currentPage - 1);
    }
};
const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
        setInputPageNumber('')
    } else {
        alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
};
// aprove model function
const handleOpenapprovedModal = (userId) => {
  setaprovedUserId(userId);
  setisVerificationModalOpen(true);
};

  const handleapproved = async (selectedBadge) => {
    const confirmed = window.confirm("Are you sure you want to approve this verification request?");
    if (confirmed) {
      // console.log(userid, 'user_iduser_iduser_id');
      setIsLoading(true);
      try {
        const data={
          userid:aprovedUserId,
          badge:selectedBadge
        }
              // console.log(data, 'user_iduser_iduser_id');

        const response = await alluserverificationrequestapi.verificationrequestapprovedbyadmin(data);
        setisVerificationModalOpen(false)

        if (response.success) {
          // Show success message
          alert("Verification request approved successfully");
          // Optionally, update UI or fetch updated data
        } else {
          // Show message returned from backend
          alert(response.message);
        }
      } catch (error) {
        console.error("Error handleapproved:", error);
        alert("Failed to handle approval");
      } finally {
        setIsLoading(false);
      }
    }
  };


  const handleDisapproved = (userid) => {
    setSelectedUserId(userid);
    setIsModalOpen(true);
  };

  const handleSubmitDisapproval = async (subject, reason, link) => {
    setIsLoading(true);
    try {
      const data = {
        reason: reason,
        user_id: selectedUserId,
        link: link
      }
      const response = await alluserverificationrequestapi.verificationrequestdisapprovedbyadmin(data)
      if (response.success) {
        alert("Verification request disapproved successfully");
        setIsModalOpen(false);
        setDisapprovalSubject('');
        setDisapprovalReason('');
        getuserverificationdetail();

      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error disapproving request:", error);
      alert("Failed to handle disapproval");
    } finally {
      setIsLoading(false);
    }
  };

  const handleviewdetail = async (userid) => {
    try {
      const response = await alluserverificationrequestapi.getdetailrequesteduserfromadminside(userid)
      console.log(response.payload, "response from blocked users");
      setuserdetail(response.payload)
      setModalIsOpen(true)

    } catch (error) {
      console.error("Error fetching users:", error);
    }
  }

  const closeModal = () => {
    setModalIsOpen(false);
    setuserdetail(null);
  };
  const isUserVerified = (userId) => {
    return document.some((doc) => doc.user_id === userId);
  };
  function formatDate(dateString) {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  }


  //handle badge type change
  const handlebadgeTypeChange = async (userId, badgeTypes) => {
    console.log(userId, badgeTypes,'userId, badgeTypes')
    try {
      if (role === "superadmin") {
        const response=await alluserverificationrequestapi.changeUserBadgeType(userId, badgeTypes);
        console.log(`Successfully changed account type to ${badgeTypes} for user with ID: ${userId}`);
       
        if(response.success){
          alert("Successfully changed User Badge type ");
          setbadgeTypes((prevTypes) => ({
            ...prevTypes,
            [userId]: badgeTypes,
          }));
        }else{
          alert(response.message);
        }

        await getuserverificationdetail();
        console.log("fetchUsers called");
      } else {
        alert("Access Denied: Only superadmins can change account types.");
      }
    } catch (error) {
      console.error("Error changing account type:", error);
    }
  };

  const handlebadgeTypeDropdownToggle = (userId) => {
    setbadgeTypeDropdownVisibility((prevVisibility) => ({
      ...prevVisibility,
      [userId]: !prevVisibility[userId],
    }));
  };
  return (

    <IncludeSideBar>
      <div>
        <p>
          Here we are displaying the sections of VerificationRequest.
        </p>
        <input
          type="text"
          placeholder="Search by user name"
        value={searchTerm}
        onChange={(e) => handleSearch(e.target.value)}
        />
        <table>
          <thead>
            <tr>
              <th>S/N</th>
              <th>User ID</th>
              <th>User Name</th>

              <th>Nick name</th>
              <th>Email</th>
              <th>Account Type</th>
              <th>Request Date</th>
              <th>Approved Date</th>



              <th>Action</th> {/* New column for the Status button */}
            </tr>
          </thead>
          <tbody>
            {Verificationdetail.map((user, index) => {
              const userIsVerified = isUserVerified(user.id);

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.id}</td>
                  <td>
                    {user.username}
                  </td>

                  <td>{user.nickname}</td>
                  <td>{user.email}</td>
                  <td>{user.account_type}</td>
                  <td>{formatDate(user.createdAt)}</td>
                  <td>{user.verified === 1 ? formatDate(user.updatedAt):''}</td>




                  <td>
                    <div style={{ display: 'flex', gap: '10px' }}>

                      <button onClick={() => handleOpenapprovedModal(user.id)} style={{ backgroundColor: user.verified === 1 ? 'green' : '' }} disabled={user.verified === 1}>
                        Approved
                      </button>

                      <button onClick={() => {
                        handleviewdetail(user.id);
                        // handleuserintraction(user.id);
                      }}>                                                View Details
                      </button>
                      <button onClick={() => handleDisapproved(user.id)} style={{ backgroundColor: user.verified === 1 ? 'gray' : '' }} disabled={user.verified === 1}>DisApproved</button>
                     <div style={ticstyle}>
                     {userIsVerified ? (
                        <FaCheckCircle style={{ color: 'green', fontSize: '24px' }} />
                      ) : (
                        <FaTimesCircle style={{ color: 'red', fontSize: '24px' }} />
                      )}
                     </div>

                     <div style={{ display: "inline-block", marginLeft: "60px" }}>
                        <label>
                          Change Badge Type:
                          <select
                            value={badgeTypes[user.id] || "Select Account"}
                            onChange={(e) => handlebadgeTypeChange(user.id, e.target.value)}
                            onClick={() => handlebadgeTypeDropdownToggle(user.id)}
                            onBlur={() => handlebadgeTypeDropdownToggle(user.id)}
                          >
                            <option value="Select Account">Select Account Type</option>
                            <option value="Simple">Simple</option>
                            <option value="Verified Top 1">Verified Top 1</option>
                            <option value="Business Account">Business Account</option>
                            <option value="Premium Users">Premium Users</option>
                            <option value="Agent">Agent</option>
                            
                          </select>
                        </label>
                        {badgeTypeDropdownVisibility[user.id] && (
                          <button onClick={() => handlebadgeTypeChange(user.id, badgeTypes[user.id])}>
                            Change Badge Type
                          </button>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}


          </tbody>
        </table>

        {isModalOpen && (
          <DisapprovalModal
            onClose={() => setIsModalOpen(false)}
            onDisapprove={handleSubmitDisapproval}
          />
        )}
        <UserDetailModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          user={userdetail}
          selectedPeriod={selectedPeriod}
          setSelectedPeriod={setSelectedPeriod}
        />
                        <div className="pagination">
                    <button onClick={handlePrevious}>Previous</button>
                    {Array.from({ length: Math.min(totalPages, 20) }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? "active" : ""}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button onClick={handleNext}>Next</button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                    <input
                        type="number"
                        value={inputPageNumber}
                        onChange={(e) => setInputPageNumber(e.target.value)}
                        placeholder="Page No"
                        style={{
                            padding: '5px',
                            fontSize: '14px',
                            // border: '1px solid #ccc',
                            border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                            borderRadius: '4px 0 0 4px',
                            outline: 'none',
                            width: '90px'
                        }}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    <button
                        onClick={handleGoToPage}
                        style={{
                            padding: '6.5px 10px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderLeft: 'none',
                            borderRadius: '0 4px 4px 0',
                            // backgroundColor: '#007bff',

                            backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                            color: 'white',
                            cursor: 'pointer',
                            outline: 'none',
                        }}


                    >
                        Go
                    </button>
                </div>

  <ApprovedModal
  isVerificationModalOpen={isVerificationModalOpen}
  setisVerificationModalOpen={setisVerificationModalOpen}
  handleapproved={handleapproved}
  />

      </div>

    </IncludeSideBar >

  )
}

export default VerificationRequest

const ticstyle={
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  
}

const DisapprovalModal = ({ onClose, onDisapprove }) => {
  const [reason, setReason] = useState('');
  const [link, setLink] = useState('');

  const handleDisapprove = () => {
    onClose();
    if (window.confirm("Are you sure you want to disapprove this verification request?")) {
      onDisapprove(reason, link);
    }
  };

  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000'
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    width: '400px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center'
  };

  const inputStyles = {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '5px',
    border: '1px solid #ccc'
  };

  const textareaStyles = {
    width: '100%',
    padding: '15px',
    margin: '15px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    resize: 'none'
  };

  const buttonStyles = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '12px 25px',
    margin: '10px 5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  };

  const buttonCancelStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    padding: '12px 25px',
    margin: '10px 5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease'
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2>Disapprove Verification Request</h2>

        <label>
          Reason:
          <textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            style={textareaStyles}
            placeholder="Reason for disapproval"
            rows="3"
          />
        </label>
        <label>
          Link:
          <input
            type="text"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            style={inputStyles}
            placeholder="Add a relevant link"
          />
        </label>
        <button
          style={buttonStyles}
          onClick={handleDisapprove}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
        >
          Submit
        </button>
        <button
          style={buttonCancelStyles}
          onClick={onClose}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};


//view details

const UserDetailModal = ({ isOpen, onRequestClose, user, selectedPeriod, setSelectedPeriod }) => {
  if (!user) return null;

  const getDisplayValue = (value) => (value ? value : 'N/A');


  const formatInteractionTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalHours = Math.floor(totalSeconds / 3600);
    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${days}D ${hours}H ${minutes}M ${seconds}S`;
  };
  const getInteractionTime = () => {
    switch (selectedPeriod) {
      case 'lastHour':
        return formatInteractionTime(user.totalInteractionTimeLastHour);
      case 'lastDay':
        return formatInteractionTime(user.totalInteractionTimeLastDay);
      case 'lastWeek':
        return formatInteractionTime(user.totalInteractionTimeLastWeek);
      case 'lastMonth':
        return formatInteractionTime(user.totalInteractionTimeLastMonth);
      case 'last3Month':
        return formatInteractionTime(user.totalInteractionTimeLast3Months);

      case 'last6Months':
        return formatInteractionTime(user.totalInteractionTimeLast6Months);
      case 'lastYear':
        return formatInteractionTime(user.totalInteractionTimeLastYear);
      default:
        return 'N/A';
    }
  };


  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
    overflow: 'auto',
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '20px 30px',
    borderRadius: '15px',
    width: '500px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'left',
    position: 'relative',
    maxHeight: '80%',
    overflowY: 'auto',
  };

  const closeButtonStyles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: '#ff4d4f',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const imgStyles = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    marginBottom: '20px',
    objectFit: 'cover',
  };

  const sectionStyles = {
    marginBottom: '10px',
  };

  const labelStyles = {
    fontWeight: 'bold',
    color: '#555',
  };

  const valueStyles = {
    marginLeft: '10px',
    color: '#333',
  };

  const tableStyles = {
    width: '100%',
    borderCollapse: 'collapse',
    marginTop: '20px',
  };

  const thStyles = {
    border: '1px solid #ddd',
    padding: '8px',
    backgroundColor: '#f2f2f2',
    fontWeight: 'bold',
    textAlign: 'left',
  };

  const tdStyles = {
    border: '1px solid #ddd',
    padding: '8px',
  };


  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <button
          style={closeButtonStyles}
          onClick={onRequestClose}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#ff1a1a')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#ff4d4f')}
        >
          &times;
        </button>
        <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>User Details</h2>
        <div style={{ textAlign: 'center' }}>
          <img src={user.profile_pic} alt="Profile" style={imgStyles} />
        </div>
        <div style={{ textAlign: 'center' }}>
          <span style={labelStyles}>Joining Date</span>
          <span style={valueStyles}>{getDisplayValue(user.creationDate)}</span>
        </div>
        {/* <h3 style={{ textAlign: 'center', marginTop: '20px' }}> User Interaction Times</h3>
        <table style={tableStyles}>
          <thead>
            <tr>
              <th style={thStyles}>Time Period</th>
              <th style={thStyles}>Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tdStyles}>Last Hour</td>
              <td style={tdStyles}>{formatInteractionTime(user.totalInteractionTimeLastHour)}</td>
            </tr>
            <tr>
              <td style={tdStyles}>Last Day</td>
              <td style={tdStyles}>{formatInteractionTime(user.totalInteractionTimeLastDay)}</td>
            </tr>
            <tr>
              <td style={tdStyles}>Last Week</td>
              <td style={tdStyles}>{formatInteractionTime(user.totalInteractionTimeLastWeek)}</td>
            </tr>
          </tbody>
        </table> */}
        <h3 style={{ textAlign: 'center', marginTop: '20px' }}> User Interaction Times</h3>
        <div style={{ textAlign: 'center', marginBottom: '10px' }}>
          <label style={labelStyles} htmlFor="time-period-select">Select Time Period:</label>
          <select
            id="time-period-select"
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
            style={{ marginLeft: '10px' }}
          >
            <option value="lastHour">Last Hour</option>
            <option value="lastDay">Last Day</option>
            <option value="lastWeek">Last Week</option>
            <option value="lastMonth">Last Month</option>
            <option value="last3Month">Last 3 Month</option>

            <option value="last6Months">Last 6 Months</option>
            <option value="lastYear">Last Year</option>
          </select>
        </div>
        <table style={tableStyles}>
          <thead>
            <tr>
              <th style={thStyles}>Time Period</th>
              <th style={thStyles}>Duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style={tdStyles}>{selectedPeriod.replace(/([A-Z])/g, ' $1').trim()}</td>
              <td style={tdStyles}>{getInteractionTime()}</td>
            </tr>
          </tbody>
        </table>

        <div style={sectionStyles}>
          <span style={labelStyles}>Role:</span>
          <span style={valueStyles}>{getDisplayValue(user.role)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Nickname:</span>
          <span style={valueStyles}>{getDisplayValue(user.nickname)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Username:</span>
          <span style={valueStyles}>{getDisplayValue(user.username)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Email:</span>
          <span style={valueStyles}>{getDisplayValue(user.email)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Account Type:</span>
          <span style={valueStyles}>{getDisplayValue(user.account_type)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Gender:</span>
          <span style={valueStyles}>{getDisplayValue(user.gender)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}> Number Of Followers:</span>
          <span style={valueStyles}>{getDisplayValue(user.followersCount)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}> Uploaded Videos:</span>
          <span style={valueStyles}>{getDisplayValue(user.videosCount)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}> Famous Profile Link:</span>
          <span style={valueStyles}>
            <a href={user.famous_profile_link} target="_blank" rel="noopener noreferrer">
              {getDisplayValue(user.famous_profile_link)}
            </a>
          </span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Wallet:</span>
          <span style={valueStyles}>{getDisplayValue(user.wallet)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>IP Address:</span>
          <span style={valueStyles}>{getDisplayValue(user.ip)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>City:</span>
          <span style={valueStyles}>{getDisplayValue(user.city)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Country:</span>
          <span style={valueStyles}>{getDisplayValue(user.country)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Person Height:</span>
          <span style={valueStyles}>{getDisplayValue(user.person_height)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Person Weight:</span>
          <span style={valueStyles}>{getDisplayValue(user.person_weight)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Facebook ID:</span>
          <span style={valueStyles}>{getDisplayValue(user.fb_id)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Hobbies:</span>
          <span style={valueStyles}>{getDisplayValue(user.hobbies)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Instagram:</span>
          <span style={valueStyles}>{getDisplayValue(user.instagram)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>YouTube:</span>
          <span style={valueStyles}>{getDisplayValue(user.you_tube)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Facebook:</span>
          <span style={valueStyles}>{getDisplayValue(user.facebook)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Occupation:</span>
          <span style={valueStyles}>{getDisplayValue(user.occupation)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Twitter:</span>
          <span style={valueStyles}>{getDisplayValue(user.twitter)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Language:</span>
          <span style={valueStyles}>{getDisplayValue(user.language)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Active:</span>
          <span style={valueStyles}>{getDisplayValue(user.active)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Verified:</span>
          <span style={valueStyles}>{getDisplayValue(user.verified)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Device:</span>
          <span style={valueStyles}>{getDisplayValue(user.device)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Latitude:</span>
          <span style={valueStyles}>{getDisplayValue(user.lat)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Longitude:</span>
          <span style={valueStyles}>{getDisplayValue(user.lang)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Online:</span>
          <span style={valueStyles}>{getDisplayValue(user.online)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Reset Wallet Datetime:</span>
          <span style={valueStyles}>{getDisplayValue(user.reset_wallet_datetime)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>City ID:</span>
          <span style={valueStyles}>{getDisplayValue(user.city_id)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Country ID:</span>
          <span style={valueStyles}>{getDisplayValue(user.country_id)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>State ID:</span>
          <span style={valueStyles}>{getDisplayValue(user.state_id)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Paypal:</span>
          <span style={valueStyles}>{getDisplayValue(user.paypal)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Bio:</span>
          <span style={valueStyles}>{getDisplayValue(user.bio)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Website:</span>
          <span style={valueStyles}>{getDisplayValue(user.website)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Date of Birth:</span>
          <span style={valueStyles}>{getDisplayValue(user.dob)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Social ID:</span>
          <span style={valueStyles}>{getDisplayValue(user.social_id)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Social:</span>
          <span style={valueStyles}>{getDisplayValue(user.social)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Auth Token:</span>
          <span style={valueStyles}>{getDisplayValue(user.auth_token)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Version:</span>
          <span style={valueStyles}>{getDisplayValue(user.version)}</span>
        </div>
        <div style={sectionStyles}>
          <span style={labelStyles}>Firebase UID:</span>
          <span style={valueStyles}>{getDisplayValue(user.firebase_uid)}</span>
        </div>

      </div>
    </div>
  );
};

///aproved badge
const ApprovedModal = ({
  isVerificationModalOpen,
  setisVerificationModalOpen,
  handleapproved
}) => {
  const [selectedBadge, setSelectedBadge] = useState('');

  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
    overflow: 'auto',
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '20px 30px',
    borderRadius: '15px',
    width: '500px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'left',
    position: 'relative',
    maxHeight: '80%',
    overflowY: 'auto',
  };
  const closeButtonStyles = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: '#ff4d4f',
    color: 'white',
    padding: '5px 10px',
    border: 'none',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };
  // const modalContentStyle = {
  //   backgroundColor: '#fff',
  //   padding: '20px',
  //   borderRadius: '8px',
  //   width: '500px',
  //   textAlign: 'center',
  //   boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  // };

  const dropdownStyle = {
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ccc',
    margin: '10px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',
    
  };
  const buttongenwrap={
    marginRight:'5px'
  }

const aprovebtnstyle={
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
}
  const badgeOptions = [
    'Simple',
    'Verified Top 1',
    'Business Account',
    'Premium Users',
    'Agent'
  ];

  const handleBadgeChange = (e) => {
    setSelectedBadge(e.target.value);
  };

  const handleApproveClick = () => {
    handleapproved(selectedBadge);
  };

  return (
    isVerificationModalOpen && (
      <div style={modalStyles}>
        <div style={modalContentStyles}>
        <button
          style={closeButtonStyles}
          onClick={()=>{setisVerificationModalOpen(false)}}
             onMouseEnter={(e) => (e.target.style.backgroundColor = '#ff1a1a')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#ff4d4f')}
        >
          &times;
        </button>

        <div style={{ marginTop: '20px' }}>
            <h3>Select Badge for  Approval</h3>
            <select
              value={selectedBadge}
              onChange={handleBadgeChange}
              style={dropdownStyle}
            >
              <option value="" disabled>Select a badge</option>
              {badgeOptions.map((badge, index) => (
                <option key={index} value={badge}>
                  {badge}
                </option>
              ))}
            </select>
            <div style={aprovebtnstyle}>
            <button onClick={handleApproveClick} style={buttonStyle} disabled={!selectedBadge}>
               Approved
            </button>
            </div>
            
          </div>
          

        </div>
       
          
      </div>
    )
  );
};
