const Visibility = Object.freeze({
  Hidden: 0,
  Visible: 1,
});



const SERVER_DOMAIN = 'https://2115.api.dreamlived.com';
const SERVER_API_URL = 'https://2115.api.dreamlived.com';



export {
  Visibility,
  SERVER_API_URL,
  SERVER_DOMAIN,
};
