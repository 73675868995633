import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";


const getAllrankedvideo = async (page,searchTerm = '') => {
 const getToken = localStorage.getItem("token");
 console.log('hello ranked')
 const config = {
   headers: { Authorization: `Bearer ${getToken}` },
 };
 // const url = `${SERVER_API_URL}/admin/ranked_video/getrankedvideo`;
   // const url = `${SERVER_API_URL}/admin/ranked_video/getrankedvideo?page=${page}&pageSize=${pageSize}`;
   const url = `${SERVER_API_URL}/admin/ranked_video/getrankedvideo?page=${page}&search=${searchTerm}`;


 const result = await axios.get(url, config);
 return result.data;
};


const deleterankedvideo = async (id,data) => {
 const getToken = localStorage.getItem("token");
 console.log('hello ranked')
 const config = {
   headers: { Authorization: `Bearer ${getToken}` },
 };
 const url = `${SERVER_API_URL}/admin/ranked_video/deleteRankedVideo/${id}`;
 const result = await axios.delete(url, config);
 return result.data;
};

const changerankvideo=async(id,data)=>{
  const getToken = localStorage.getItem("token");
  console.log('hello ranked')
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/ranked_video/changerank/${id}`;
  const result = await axios.put(url,data,config);
  return result.data;
}




const getVideo = async () => {
 try {
   const getToken = localStorage.getItem("token");
  //  const url = `${SERVER_API_URL}/admin/ranked_video/otherapi?page=${page}&pageSize=${pageSize}`;
  const url = `${SERVER_API_URL}/admin/ranked_video/otherapi`;


   const config = {
     headers: { Authorization: `Bearer ${getToken}` },
     
   };
   const result = await axios.get(url,config);
   return result.data;
 } catch (error) {
   console.error(error);
   throw error;
 }
};














export { getAllrankedvideo,deleterankedvideo,getVideo,changerankvideo};
