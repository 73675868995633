import { SERVER_API_URL } from "../constants/constants";
import axios from "axios";

const getAllVerificationdetalbyuser = async (page,searchTerm = '') => {
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/verification_request_by_user/getallverificationdetailbyuser`;
  const url = `${SERVER_API_URL}/admin/verification_request_by_user/getallverificationdetailbyuser?page=${page}&search=${searchTerm}`;

  const result = await axios.get(url, config);
  return result.data;
};

const verificationrequestapproved=async(data)=>{
    const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    // const url = `${SERVER_API_URL}/admin/users/getBasicUsers`;
    // const url = `${SERVER_API_URL}/admin/verification_request_by_user/verificationrequestapproved/${userid}`;
    const url = `${SERVER_API_URL}/admin/verification_request_by_user/verificationrequestapproved`;

    const result = await axios.post(url,data,config);
    return result.data;
}


const verificationrequestdisapproved=async(data)=>{
  const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    // const url = `${SERVER_API_URL}/admin/users/getBasicUsers`;
    const url = `${SERVER_API_URL}/admin/verification_request_by_user/verificationrequestdisapproved`;
  
    const result = await axios.post(url, data,config);
    return result.data;
}
const getdetailrequesteduser=async(userid)=>{
  const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    const url = `${SERVER_API_URL}/admin/verification_request_by_user/getdetailforrequesteduser/${userid}`;
  
    const result = await axios.get(url,config);
    return result.data;
}



// by admin 


// verificationrequestapprovedbyadmin
const verificationrequestapprovedbyadmin=async(data)=>{
  const getToken = localStorage.getItem("token");
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  // const url = `${SERVER_API_URL}/admin/users/getBasicUsers`;
  // const url = `${SERVER_API_URL}/admin/verification_request_by_user/verificationrequestapprovedbyadmin/${userid}`;
  const url = `${SERVER_API_URL}/admin/verification_request_by_user/verificationrequestapprovedbyadmin`;

  const result = await axios.post(url, data,config);
  return result.data;
}


const verificationrequestdisapprovedbyadmin=async(data)=>{
  const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    // const url = `${SERVER_API_URL}/admin/users/getBasicUsers`;
    const url = `${SERVER_API_URL}/admin/verification_request_by_user/verificationrequestdisapprovedbyadmin`;
  
    const result = await axios.post(url, data,config);
    return result.data;
}


const getdetailrequesteduserfromadminside=async(userid)=>{
  const getToken = localStorage.getItem("token");
    const config = {
      headers: { Authorization: `Bearer ${getToken}` },
    };
    const url = `${SERVER_API_URL}/admin/verification_request_by_user/getdetailforrequesteduserbyadmin/${userid}`;
  
    const result = await axios.get(url,config);
    return result.data;
}

const changeUserBadgeType = async (id, badge_type) => {
  console.log(id, badge_type, "badge_type")
  const getToken = localStorage.getItem('token');
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/verification_request_by_user/changeUserbadgetype/${id}`;

  try {
    const result = await axios.patch(url, { badge_type }, config);
    console.log(result, "resultfromfrontend");
    return result.data;
  } catch (error) {
    throw error; // Propagate the error for handling in your component
  }
};
export { getAllVerificationdetalbyuser, verificationrequestapproved,verificationrequestdisapproved,getdetailrequesteduser,verificationrequestapprovedbyadmin,verificationrequestdisapprovedbyadmin,getdetailrequesteduserfromadminside,changeUserBadgeType}