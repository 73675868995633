import React from 'react'
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'


function BusinessUsers() {
  return (
   <IncludeSideBar>
     <div>
        <p>
            Here we display the details of Business account
        </p>
     </div>
   </IncludeSideBar>
  )
}

export default BusinessUsers