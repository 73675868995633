import { useState, useEffect } from 'react';
import * as authApi from '../src/apis/auth'
function Verifyauth() {
  const [isAuth, setIsAuth] = useState(false);
  const [userinfo, setUserinfo] = useState(null);

  const getuserInfo = async () => {
    const result = await authApi.usersignininfo()
    setUserinfo(result?.payload)
  }

  useEffect(() => {
    const token = localStorage.getItem('usertoken');
console.log(token,'token is auth')

    if (token) {
        setIsAuth(true);
      getuserInfo()
    } else {
        setIsAuth(false);
        setUserinfo(null);
    }
  }, []);

  return {
    isAuth,
    userinfo,
  };
}

export default Verifyauth;

