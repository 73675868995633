// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';

// const useRedirectToLastPage = () => {
//   const navigate = useNavigate();

//   useEffect(() => {
//     const currentPath = window.localStorage.getItem('currentPath');
//     if (currentPath) {
//       navigate(currentPath);
//     }
//   }, [navigate]);
// };

// export default useRedirectToLastPage;



// useRedirectToLastPage.js
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useRedirectToLastPage = (shouldExecute) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (shouldExecute) {
      const currentPath = window.localStorage.getItem('currentPath');
      if (currentPath) {
        navigate(currentPath);
      }
    }
  }, [navigate, shouldExecute]);
};

export default useRedirectToLastPage;
