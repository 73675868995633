import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar';

function AllReports() {
  

  return (
    <IncludeSideBar>
      <div>
        <p>
          Here we are displaying the All reports.
        </p>
       
      </div>
    </IncludeSideBar>
  );
}

export default AllReports;
