

import React, { useState,useEffect } from 'react';
import * as authApi from '../../../apis/users'
import * as verifydocumentapi from '../../../apis/verifydocumentupload'
import { jwtDecode } from 'jwt-decode';

function VerificationDocument() {
  
  const [photo, setPhoto] = useState(null);
  const [photoPreview, setPhotoPreview] = useState(null); // State for photo preview

  const [idProofType, setIdProofType] = useState('');
  // const [idProofFront, setIdProofFront] = useState(null);
  // const [idProofBack, setIdProofBack] = useState(null);
  const [passport, setPassport] = useState(null);
  const [passportPreview, setpassportPreview] = useState(null); // State for photo preview

  const [drivingLicenceFront, setDrivingLicenceFront] = useState(null);
  const [drivingLicenceFrontPreview, setdrivingLicenceFrontPreview] = useState(null); // State for photo preview

  const [drivingLicenceBack, setDrivingLicenceBack] = useState(null);
  const [drivingLicenceBackPreview, setdrivingLicenceBackPreview] = useState(null); // State for photo preview

  const [otherIdProofFront, setOtherIdProofFront] = useState(null);
  const [otherIdProofFrontPreview, setotherIdProofFrontPreview] = useState(null); // State for photo preview

  const [otherIdProofBack, setOtherIdProofBack] = useState(null);
  const [otherIdProofBackPreview, setotherIdProofBackPreview] = useState(null); // State for photo preview

  const [userinfo, setUserinfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleFileChange = (event, setFile,setPreview) => {
    setFile(event.target.files[0]);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreview(reader.result);
    };
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
    
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append('photo', photo);
    formData.append('UserId', userinfo[0].id);

    if (idProofType === 'passport') {
      formData.append('passport', passport);
    } else if (idProofType === 'drivingLicence') {
      formData.append('drivingLicenceFront', drivingLicenceFront);
      formData.append('drivingLicenceBack', drivingLicenceBack);
    } else if (idProofType === 'otherIdProof') {
      formData.append('otherIdProofFront', otherIdProofFront);
      formData.append('otherIdProofBack', otherIdProofBack);
    }

    try {
      const response = await verifydocumentapi.uploaddocument(formData)
      setPhoto(null);
      setPhotoPreview(null);
      setPassport(null);
      setpassportPreview(null)
      setDrivingLicenceFront(null);
      setdrivingLicenceFrontPreview(null)
      setDrivingLicenceBack(null);
      setdrivingLicenceBackPreview(null)
      setOtherIdProofFront(null);
      setotherIdProofFrontPreview(null)
      setOtherIdProofBack(null);
      setotherIdProofBackPreview(null)
      if (response.success){
        alert("Document uploaded and saved successfully");

      }else{
        alert(response.message)
      }
       
      // console.log('Files uploaded and saved successfully:', response);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  

  useEffect(() => {
    const getTokenFromUrl = () => {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get('token');
    };

    const fetchUserinfo = async (userId) => {
      setIsLoading(true);


      try {
        const result = await authApi.getfordocumentverificationUserById(userId); // Assuming you have an API endpoint to get user info by ID
        setUserinfo(result.data);
        setIsLoading(false);

        console.log(userinfo[0].profile_pic,'profile')
        console.log(result.data,'result.data')
      } catch (error) {
        console.error('Error fetching user info:', error);

      }
    };

    const token = getTokenFromUrl();
    // console.log(token,'tokentokentokentoken')
    if (token) {
      const decodedToken = jwtDecode(token);
      // console.log(decodedToken,'decodedTokendecodedToken')
      const userId = decodedToken.verificationUserId;
      console.log(userId,'userIduserIduserIduserId')
      fetchUserinfo(userId);
    }
    const decodedToken = jwtDecode(token);
  const currentTimestamp = Date.now() / 1000; // Convert to seconds

  if (decodedToken.exp < currentTimestamp) {
    // Token has expired, handle the expired token scenario
    setError(true);
    return;
  }


  }, []);

  if (isLoading) {
    return <p>Loading...</p>;
  }
  if (error) {
    return (
      <div style={containerStyle}>
        <h2 style={headerStyle}>Dream Document Verification</h2>
        <p>Link Expire</p>
      </div>
    );
  }
  
  return (
      <div style={containerStyle}>
        <h2 style={headerStyle}>Dream Document Verification</h2>
        <p>Please upload the following documents for verification:</p>
        {userinfo && (
         <div style={profileContainerStyle}>
          <img src={userinfo[0].profile_pic} alt="Profile" style={profilePicStyle} />


         <div style={userInfoStyle}>
           <span><strong>ID:</strong> {userinfo[0].id}</span>
           <span><strong>Username:</strong> {userinfo[0].username}</span>
           <span><strong>Email:</strong> {userinfo[0].email}</span>

         </div>
       
          
       </div>
        )}
        <form onSubmit={handleSubmit} style={formStyle}>
          <div style={formGroupStyle}>
            <label style={labelStyle}>Clear Photo:</label>
            <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setPhoto,setPhotoPreview)} style={inputStyle} />
            {photoPreview && (
            <div style={previewContainer}>
              <img src={photoPreview} alt="Photo Preview" style={previewImageStyle} />
            </div>
          )}
          </div>
          <div style={formGroupStyle}>
            <label style={labelStyle}>Select ID Proof Type:</label>
            <select onChange={(e) => setIdProofType(e.target.value)} value={idProofType} style={inputStyle}>
              <option value="">Select an option</option>
              <option value="passport">Passport</option>
              <option value="drivingLicence">Driving Licence</option>
              <option value="otherIdProof">Other Government-approved ID</option>
            </select>
          </div>
          {idProofType === 'passport' && (
            <div style={formGroupStyle}>
              <label style={labelStyle}>Passport:</label>
              <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setPassport,setpassportPreview)} style={inputStyle} />
              {passportPreview && (
            <div style={previewContainer}>
              <img src={passportPreview} alt="Photo Preview" style={previewImageStyle} />
            </div>
          )}
            </div>
          )}
          {idProofType === 'drivingLicence' && (
            <>
              <div style={formGroupStyle}>
                <label style={labelStyle}>Driving Licence (Front):</label>
                <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setDrivingLicenceFront,setdrivingLicenceFrontPreview)} style={inputStyle} />
                {drivingLicenceFrontPreview && (
            <div style={previewContainer}>
              <img src={drivingLicenceFrontPreview} alt="Photo Preview" style={previewImageStyle} />
            </div>
          )}
              </div>
              <div style={formGroupStyle}>
                <label style={labelStyle}>Driving Licence (Back):</label>
                <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setDrivingLicenceBack,setdrivingLicenceBackPreview)} style={inputStyle} />
                {drivingLicenceBackPreview && (
            <div style={previewContainer}>
              <img src={drivingLicenceBackPreview} alt="Photo Preview" style={previewImageStyle} />
            </div>
          )}
              </div>
            </>
          )}
          {idProofType === 'otherIdProof' && (
            <>
              <div style={formGroupStyle}>
                <label style={labelStyle}>Other ID Proof (Front):</label>
                <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setOtherIdProofFront,setotherIdProofFrontPreview)} style={inputStyle} />
                {otherIdProofFrontPreview && (
            <div style={previewContainer}>
              <img src={otherIdProofFrontPreview} alt="Photo Preview" style={previewImageStyle} />
            </div>
          )}
              </div>
              <div style={formGroupStyle}>
                <label style={labelStyle}>Other ID Proof (Back):</label>
                <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setOtherIdProofBack,setotherIdProofBackPreview)} style={inputStyle} />
                {otherIdProofBackPreview && (
            <div style={previewContainer}>
              <img src={otherIdProofBackPreview} alt="Photo Preview" style={previewImageStyle} />
            </div>
          )}
              </div>
            </>
          )}
          <p>All documents are mandatory for verification.</p>
         
          <button type="submit" style={{ ...submitButtonStyle, ':hover': submitButtonHoverStyle }}>Submit</button>
        </form>
      </div>
  );
}

export default VerificationDocument;

const containerStyle = {
  maxWidth: '800px',
  margin: 'auto',
  padding: '20px',
  backgroundColor: '#f9f9f9',
  marginTop: '7%',
  borderRadius: '8px',
  boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
};

const headerStyle = {
  textAlign: 'center',
  color: 'blue',
};

const formStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
};

const formGroupStyle = {
  display: 'flex',
  flexDirection: 'column',
};


const labelStyle = {
  marginBottom: '5px',
  color: '#555',
};

const inputStyle = {
  padding: '5px',
};


const submitButtonStyle = {
  padding: '10px 15px',
  backgroundColor: '#4CAF50',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  cursor: 'pointer',
  fontSize: '16px',
  alignSelf: 'center',
};

const submitButtonHoverStyle = {
  backgroundColor: '#45a049',
};
const profileContainerStyle = {
  display: 'flex',
  marginBottom: '20px',
  flexDirection:'row',
  alignItems: 'center',

  // backgroundColor:'red'
};

const profilePicStyle = {
  width: '150px',
  height: '150px',
  borderRadius: '50%',
  objectFit: 'cover',
  marginRight: '20px',
};

const userInfoStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',  
  // backgroundColor:'green'
};
const previewContainer = {
  display: 'flex',
  // flexDirection: 'column',
  // alignItems: 'center',
  margin: '10px',
  marginRight:'40%',
  // backgroundColor:'green'
};

const previewImageStyle = {
  width: '50px',
  height: '50px',
  // borderRadius: '50%',
  objectFit: 'cover',
  // marginRight: '20px',
 
};



