import React, { useEffect, useState } from "react";
import VideoModal from './VideoModal';
import PhotoModal from './PhotoModal';
import './AllUser.css'
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import useAuth from '../../../useAuth'


const AllUser = () => {
  const allUserApis = require("../../../apis/users");
  const { user } = useAuth()
  const role = user ? user.role : null;
  console.log(role, "roooolllee")
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [videoData, setVideoData] = useState(null);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const [interactionData, setInteractionData] = useState([]);
  const [interactionTime1Day, setInteractionTime1Day] = useState(0);
  const [interactionTime15Days, setInteractionTime15Days] = useState(0);
  const [interactionTime1Month, setInteractionTime1Month] = useState(0);
  const [interactionTime3Month, setInteractionTime3Month] = useState(0);
  const [searchTerm, setSearchTerm] = useState('')
  const [loading, setLoading] = useState(false);
  const [photoData, setPhotoData] = useState(null);
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [userid, setuserid] = useState(null)

  const [likedata, setlikedata] = useState([])
  const [commentdata, setcommentdata] = useState([])
  const [diamonddata, setdiamonddata] = useState([])
  const [sharedata, setsharedata] = useState([])
  const [interactionType, setInteractionType] = useState(null);
  const [senddiamonddata, setsenddiamonddata] = useState([])
  const [sendlikedata, setsendlikedata] = useState([])
  const [recivedcommentdata, setrecivedcommentdata] = useState([])
  const [recivesharedata, setrecivesharedata] = useState([])
  const [pageWindow, setPageWindow] = useState({ start: 1, end: 20 });


  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);

  // for user model delete
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  //for block model

  const [isblockModalOpen, setblockIsModalOpen] = useState(false);
  const [selectedblockUserId, setSelectedblockUserId] = useState(null);

  const getUsers = async (page, searchTerm = '') => {
    try {
      setLoading(true);
      const response = await allUserApis.getAllUsers(page, searchTerm);
      setUserData(response.users);
      setTotalPages(response.pagination.totalPages);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false); // Hide loader after data retrieval (success or failure)
    }
  };

  useEffect(() => {
    getUsers(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  const handleSearch = (searchValue) => {
    setSearchTerm(searchValue);
  };


  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
    }
   
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      // setCurrentPage((prevPage) => prevPage - 1);
      setCurrentPage(currentPage - 1);
      setPageWindow({
        start: Math.floor((currentPage - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((currentPage - 1) / 20) * 20 + 20, totalPages),
      });
     
    }
  };

  const handleNextPageWindow = () => {
    if (pageWindow.end < totalPages) {
      setPageWindow({
        start: pageWindow.start + 19,
        end: Math.min(pageWindow.end + 19, totalPages),
      });
    }
  };

  const handlePreviousPageWindow = () => {
    if (pageWindow.start > 1) {
      setPageWindow({
        start: pageWindow.start - 20,
        end: pageWindow.end - 20,
      });
    }
  };

  const handlePageClick = (page) => {
    handlePageChange(page);
    if (page === pageWindow.end && pageWindow.end < totalPages) {
      handleNextPageWindow();
    } else if (page === pageWindow.start && pageWindow.start > 1) {
      handlePreviousPageWindow();
    }
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
    setPageWindow({
      start: Math.floor((totalPages - 1) / 20) * 20 + 1,
      end: totalPages,
    });
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
    setPageWindow({
      start: 1,
      end: Math.min(20, totalPages),
    });
  };

  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setPageWindow({
        start: Math.floor((pageNumber - 1) / 20) * 20 + 1,
        end: Math.min(Math.floor((pageNumber - 1) / 20) * 20 + 20, totalPages),
      });
      setInputPageNumber('');
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };
  
  const handleViewVideo = async (userId) => {
    try {
      setLoading(true);

      const response = await allUserApis.getUserVideos(userId);
      setVideoData(response);
      setShowVideoModal(true);
    } catch (error) {
      console.error("Error fetching users:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleViewPhoto = async (userId) => {
    try {
      setLoading(true);
      const response = await allUserApis.getAllUsersPost(userId);
      console.log(response, "responsefrom alluser")
      setPhotoData(response.photos);
      setShowPhotoModal(true);
    } catch (error) {
      console.error("Error fetching user photos:", error);
    } finally {
      setLoading(false);
    }
  };

  console.log(photoData, 'photoDataphotoData')
  const handleClosePhotoModal = () => {
    setShowPhotoModal(false);
    setPhotoData(null);

  };



  const handleUsage = (userId) => {
    const user = userData.find((user) => user.id === userId);
    setuserid(userId)
    if (user && user.user_interactions) {
      const currentTime = new Date();
      setInteractionData(user.user_interactions);
      const interactionTimes = calculateInteractionTimes(user.user_interactions, currentTime);
      setInteractionTime1Day(interactionTimes.interactionTime1Day);
      setInteractionTime15Days(interactionTimes.interactionTime15Days);
      setInteractionTime1Month(interactionTimes.interactionTime1Month);
      setInteractionTime3Month(interactionTimes.interactionTime3Month);

    }
    const element = document.getElementById('user-interaction-details');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleCloseUsageDetails = () => {
    // Scroll back to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' });

    // Clear the interaction data and close the section
    setInteractionData([]);
    setInteractionTime1Day(0);
    setInteractionTime15Days(0);
    setInteractionTime1Month(0);
    setInteractionTime3Month(0);
  };

  // Calculate interaction times for different durations
  const calculateInteractionTimes = (interactionData, currentTime) => {
    const oneDayAgo = new Date(currentTime);
    oneDayAgo.setDate(currentTime.getDate() - 1);
    const fifteenDaysAgo = new Date(currentTime);
    fifteenDaysAgo.setDate(currentTime.getDate() - 15);
    const oneMonthAgo = new Date(currentTime);
    oneMonthAgo.setMonth(currentTime.getMonth() - 1);
    const threeMonthsAgo = new Date(currentTime);
    threeMonthsAgo.setMonth(currentTime.getMonth() - 3);

    const interactionTime1Day = calculateInteractionTime(interactionData, oneDayAgo);
    const interactionTime15Days = calculateInteractionTime(interactionData, fifteenDaysAgo);
    const interactionTime1Month = calculateInteractionTime(interactionData, oneMonthAgo);
    const interactionTime3Month = calculateInteractionTime(interactionData, threeMonthsAgo);


    return {
      interactionTime1Day,
      interactionTime15Days,
      interactionTime1Month,
      interactionTime3Month
    };
  };

  // Calculate total interaction time in human-readable format
  const calculateInteractionTime = (interactionData, startDate) => {
    const interactionsWithinRange = interactionData.filter((interaction) => {
      const interactionStart = new Date(interaction.interaction_start);
      return interactionStart >= startDate;
    });

    const totalInteractionTimeInMilliseconds = interactionsWithinRange.reduce(
      (total, interaction) => total + interaction.interacted_time,
      0
    );

    const totalSeconds = Math.floor(totalInteractionTimeInMilliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours} hours ${minutes} minutes ${seconds} seconds`;
  };

  // function for handling the active user to unactive 

  // const handleBlockUser = async (userId) => {


  //   // Ask for confirmation before blocking the user
  //   const confirmed = window.confirm("Are you sure you want to block this user?");

  //   if (confirmed) {
  //     const updatedUserData = userData.map(user => {
  //       if (user.id === userId && user.active === 1) {
  //         return { ...user, active: 0 };
  //       }
  //       return user;
  //     });

  //     // Update local state with the updated user data
  //     setUserData(updatedUserData);

  //     try {
  //       console.log('Calling updateResourceActiveStatus with role:', role);
  //       const response = await allUserApis.updateResourceActiveStatus(userId, 0,role);
  //       console.log('User status updated successfully:', response);
  //       // Perform any necessary action after a successful update on the server
  //     } catch (error) {
  //       console.error('Error updating user status:', error);

  //     }
  //   } else {

  //     console.log('Block action canceled by the user.');
  //     return;
  //   }
  // };

  const handleBlockUser = async (userId, totalSeconds, reason) => {
    console.log(userId, totalSeconds, reason, 'userId,totalSeconds,reason')
    const updatedUserData = userData.map(user => {
      if (user.id === userId && user.active === 1) {
        return { ...user, active: 0 };
      }
      return user;
    });

    setUserData(updatedUserData);

    try {
      console.log('Calling updateResourceActiveStatus with role:', role);
      const response = await allUserApis.updateResourceActiveStatus(userId, 0, role, totalSeconds, reason);
      console.log('User status updated successfully:', response);
      // Perform any necessary action after a successful update on the server
    } catch (error) {
      console.error('Error updating user status:', error);
    }
  };

  const openblockModal = (userId) => {
    setSelectedblockUserId(userId);
    setblockIsModalOpen(true);
  };

  const closeblockModal = () => {
    setblockIsModalOpen(false);
    setSelectedblockUserId(null);
  };

  const handleDeleteUser = async (userId, days) => {
    try {
      const data = {
        userId: userId,
        // deleteAfter:'1'
        deleteAfter: days.toString()

      }
      setLoading(true);
      const response = await allUserApis.AccountDeletionRequest(data);
      console.log(response, "response")

      if (response.success === true) {
        alert('Successfully initiated account deletion request');

      } else if (response.message == 'all ready exit') {
        alert('Account deletion request already exists for this user')
      }
    } catch (error) {
      console.error("Error fetching user photos:", error);
    } finally {
      setLoading(false);
    }
  }


  const openModal = (userId) => {
    console.log(userId, 'userId open model')
    setSelectedUserId(userId);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedUserId(null);
  };

  const handleDelete = (days) => {
    handleDeleteUser(selectedUserId, days);
    closeModal();
  };





  const handleCloseVideoModal = () => {
    setShowVideoModal(false);
    setVideoData(null);
  };

  const updateVideoData = (updatedData) => {
    setVideoData(updatedData);
  };




  const tdStyle = {
    border: '1px solid #ddd',
    padding: '8px',
    textAlign: 'center'
  };

  const tdEmailStyle = {
    ...tdStyle,
    width: '150px', // Set the desired width for the email cell
  };

  // const getlikedata = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await allUserApis.getlikeintraction(userid);
  //     setlikedata(response.payload)
  //   //  console.log(response.payload,'response')
  //   } catch (error) {
  //     console.error("Error fetching users:", error);
  //   } finally {
  //     setLoading(false); // Hide loader after data retrieval (success or failure)
  //   }
  // };
  // const getcommentdata = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await allUserApis.getcommentintraction(userid);
  //     setcommentdata(response.payload)
  //   //  console.log(response.payload,'response')
  //   } catch (error) {
  //     console.error("Error fetching users:", error);
  //   } finally {
  //     setLoading(false); // Hide loader after data retrieval (success or failure)
  //   }
  // };
  const fetchData = async (interactionType) => {
    try {
      setLoading(true);

      if (interactionType === 'like') {
        const response = await allUserApis.getlikeintraction(userid);
        setlikedata(response.payload);
        if (response.payload.ninty_days === 0) {
          alert('Like Data is not avialble')
        }
      } else if (interactionType === 'comment') {
        const response = await allUserApis.getcommentintraction(userid);
        setcommentdata(response.payload);
        if (response.payload.ninty_days === 0) {
          alert('Comment  Data is not avialble')
        }
      } else if (interactionType === 'Diamond') {
        const response = await allUserApis.getdiamondintraction(userid)
        // console.log(response,'responsediamond')
        setdiamonddata(response.payload)
        if (response.payload.ninty_days === 0) {
          alert('Diamond Data is not avialble')
        }
      } else if (interactionType === 'Share') {
        const response = await allUserApis.getshareintraction(userid)
        // console.log(response,'responsegetshareintraction')
        setsharedata(response.payload)
        if (response.payload.ninty_days === 0) {
          // Show alert message when data is available
          alert('Share Data is not available!');
        }
      } else if (interactionType === 'Send Diamond') {
        const response = await allUserApis.getsenddiamond(userid)
        setsenddiamonddata(response.payload)
        if (response.payload.ninty_days === 0) {
          alert('Sended Diamond Data is not available')
        }
      } else if (interactionType === 'Send Like') {
        const response = await allUserApis.getsendlikeintraction(userid)
        setsendlikedata(response.payload)
        if (response.payload.ninty_days === 0) {
          alert('Sended like Data is not available')
        }
      } else if (interactionType === 'recived comment') {
        const response = await allUserApis.getrecivedcommetintraction(userid)
        // console.log(response.payload,'recived comment')
        setrecivedcommentdata(response.payload)
        if (response.payload.ninty_days === 0) {
          alert('Comment Data is not available')
        }
      } else if (interactionType === 'recive share') {
        const response = await allUserApis.getrecivedshare(userid)
        setrecivesharedata(response.payload)
        if (response.payload.ninty_days === 0) {
          alert('Recived share Data is Not available')
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (interactionType) {
      fetchData(interactionType);
    }
  }, [interactionType]);



  //Add follower
  const [CurrentfolloweruserIndex, setCurrentfolloweruserIndex] = useState(null)
  const [ShowfollowerModal, setShowfollowerModal] = useState(false)
  const [CurrentunfolloweruserIndex, setCurrentunfolloweruserIndex] = useState(null)
  const [ShowunfollowerModal, setShowunfollowerModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const openAddfollowerModal = (index) => {
    setCurrentfolloweruserIndex(index);
    setShowfollowerModal(true);
  }
  const closeaddfollowerModal = () => {
    setShowfollowerModal(false)
    setShowunfollowerModal(false)
  }
  const handleaddfollower = async (addfollower) => {
    if (addfollower !== null) {
      try {
        setIsLoading(true);
        await allUserApis.updateuserfollower(CurrentfolloweruserIndex, addfollower);
        getUsers(currentPage, '')

        setIsLoading(false);

        alert(`Add ${addfollower} Follower succsefully  `);

        setShowfollowerModal(false)
      } catch (error) {
        setIsLoading(false);
        console.error('Error updating shared count:', error);
      }
    }
  };
  //unfollow
  const openunfollowerModal = (index) => {
    setCurrentunfolloweruserIndex(index);
    setShowunfollowerModal(true);
  }

  const handleunfollow = async (unfollow) => {

    if (unfollow !== null) {
      try {
        setIsLoading(true);

        // Simulating an API call to update the shared count (replace this with the actual API call)
        await allUserApis.updateuserunfollow(CurrentunfolloweruserIndex, unfollow);
        getUsers(currentPage, searchTerm)

        setIsLoading(false);

        alert(`${unfollow} Unfollow succsefully  `);

        setShowunfollowerModal(false)
      } catch (error) {
        setIsLoading(false);
        console.error('Error updating shared count:', error);
      }
    }
  }





  //pagination


  return (
    <IncludeSideBar>
      <div>

        <input
          type="text"
          placeholder="Search by username"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
        />
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        {isModalOpen && <DeleteModal onClose={closeModal} onDelete={handleDelete} />}

        {isblockModalOpen && (
          <BlockModal
            onClose={closeblockModal}
            onBlock={(totalSeconds, reason) => handleBlockUser(selectedblockUserId, totalSeconds, reason)}
          />
        )}
        <table>

          <thead>
            <tr>
              <th>ID</th>
              <th>Profile Pic</th>
              <th>Username</th>
              <th>Account Type</th>
              <th>Nickname</th>
              <th>Email</th>
              <th>followers</th>

              <th>Gender</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {userData.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>
                  <img
                    //   src={`https://dpcst9y3un003.cloudfront.net/${user.profile_pic}`}
                    src={user.profile_pic}

                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "20%",
                    }}
                  />
                </td>
                <td>{user.username}</td>
                <td>{user.account_type}</td>
                <td>{user.nickname}</td>
                <td style={tdEmailStyle}>{user.email}</td>
                <td style={tdEmailStyle}>{user.followersCount}</td>

                <td>{user.gender}</td>
                <td>
                  {user.active === 0 ? "Blocked" : (
                    <div style={{ display: 'flex', gap: '10px' }}>
                      <button onClick={() => handleViewVideo(user.id)}>View Video</button>
                      <button onClick={() => handleUsage(user.id)}>Usage</button>
                      {/* <button onClick={() => handleBlockUser(user.id)}>Block User</button> */}
                      <button onClick={() => openblockModal(user.id)}>Block User</button>

                      <button onClick={() => handleViewPhoto(user.id)}>Photo</button>
                      {/* <button onClick={() => handleDeleteUser(user.id)}>Delete</button> */}
                      <button onClick={() => openModal(user.id)}>Delete</button>
                      <button onClick={() => openAddfollowerModal(user.id)}>Add Follow</button>
                      <button onClick={() => openunfollowerModal(user.id)}>UnFollow</button>



                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div>
          <button onClick={() => setInteractionType('like')}>Recived Like</button>
          <button onClick={() => setInteractionType('Send Like')}>Send Like</button>

          <button onClick={() => setInteractionType('comment')}> Reply Comment</button>
          <button onClick={() => setInteractionType('recived comment')}>Recived Comment</button>
          <button onClick={() => setInteractionType('Diamond')}>Recived Diamond</button>
          <button onClick={() => setInteractionType('Send Diamond')}>Send Diamond</button>
          <button onClick={() => setInteractionType('Share')}>Send Share</button>
          <button onClick={() => setInteractionType('recive share')}>Recived Share</button>
        </div>
        <VideoModal
          showVideoModal={showVideoModal}
          handleCloseVideoModal={handleCloseVideoModal}
          videoData={videoData}
          setVideoData={updateVideoData}
          handleViewVideo={handleViewVideo}
        />
        <PhotoModal
          showPhotoModal={showPhotoModal}
          handleClosePhotoModal={handleClosePhotoModal}
          photoData={photoData}
        />
        {interactionData.length > 0 && (
          <div id="user-interaction-details">
            <button onClick={handleCloseUsageDetails} style={{ float: 'right', marginBottom: '10px' }}>
              Close
            </button>

            <h2>User Usage</h2>
            {/* <div>
              <button onClick={getlikedata}>Like</button>
              <button> Comments</button>
              <button>Share</button>

              </div> */}
            <table>
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Interaction Time</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr> */}
                <tr>
                  <td>Last 15 Days</td>
                  <td>{interactionTime15Days} seconds</td>
                </tr>
                <tr>
                  <td>Last 1 Month</td>
                  <td>{interactionTime1Month} seconds</td>
                </tr>
                <tr>
                  <td>Last 3 Month</td>
                  <td>{interactionTime3Month} seconds</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}


        {/* //like intraction data  */}


        {likedata.ninty_days > 0 && interactionType === 'like' && (
          <div id="user-interaction-details">
            <button onClick={handleCloseUsageDetails} style={{ float: 'right', marginBottom: '10px' }}>
              Close
            </button>

            <h2>User Usage</h2>
            {/* <div>
              <button onClick={getlikedata}>Like</button>
              <button> Comments</button>
              <button>Share</button>

              </div> */}
            <table>
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Number Of Like During Interaction Time</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr> */}
                <tr>
                  <td>Last 15 Days</td>
                  <td>{likedata.fifteen_days} Like</td>
                </tr>
                <tr>
                  <td>Last 1 Month</td>
                  <td>{likedata.thirty_days} Like</td>
                </tr>
                <tr>
                  <td>Last 3 Month</td>
                  <td>{likedata.ninty_days} Like</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {
          sendlikedata.ninty_days > 0 && interactionType === 'Send Like' && (
            <div id="user-interaction-details">
              <button onClick={handleCloseUsageDetails} style={{ float: 'right', marginBottom: '10px' }}>
                Close
              </button>

              <h2>User Usages</h2>
              <table>
                <thead>
                  <th>Time Range</th>
                  <th>Number Of Send Like During Interaction Time</th>

                </thead>
                <tbody>
                  <tr>
                    <td>Last 15 Days</td>
                    <td>{sendlikedata.fifteen_days} like Sended by User </td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{sendlikedata.thirty_days} like Sended by User</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{sendlikedata.ninty_days} like Sended by User</td>
                  </tr>

                </tbody>
              </table>
            </div>
          )
        }


        {commentdata.ninty_days > 0 && interactionType === 'comment' && (
          <div id="user-interaction-details">
            <button onClick={handleCloseUsageDetails} style={{ float: 'right', marginBottom: '10px' }}>
              Close
            </button>

            <h2>User Usage</h2>
            {/* <div>
              <button onClick={getlikedata}>Like</button>
              <button> Comments</button>
              <button>Share</button>

              </div> */}
            <table>
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Number Of Comments During Interaction Time</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr> */}
                <tr>
                  <td>Last 15 Days</td>
                  <td>{commentdata.fifteen_days} Comments</td>
                </tr>
                <tr>
                  <td>Last 1 Month</td>
                  <td>{commentdata.thirty_days} Comments</td>
                </tr>
                <tr>
                  <td>Last 3 Month</td>
                  <td>{commentdata.ninty_days} Comments</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}


        {
          recivedcommentdata.ninty_days > 0 && interactionType === 'recived comment' && (
            <div id="user-interaction-details">
              <h2>User Usage</h2>
              <table>
                <thead>
                  <tr>
                    <th>Time Range</th>
                    <th>Number Of Recived Comment During Interaction Time</th>
                  </tr>
                </thead>
                <tbody>
                  {/* <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr> */}
                  <tr>
                    <td>Last 15 Days</td>
                    <td>{recivedcommentdata.fifteen_days} comment</td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{recivedcommentdata.thirty_days} comment</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{recivedcommentdata.ninty_days} comment</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        }

        {diamonddata.ninty_days > 0 && interactionType === 'Diamond' && (
          <div id="user-interaction-details">
            <h2>User Usage</h2>

            <table>
              <thead>
                <tr>
                  <th>Time Range</th>
                  <th>Number Of Diamond During Interaction Time</th>
                </tr>
              </thead>
              <tbody>
                {/* <tr>
                  <td>Last 1 Day</td>
                  <td>{interactionTime1Day} seconds</td>
                </tr> */}
                <tr>
                  <td>Last 15 Days</td>
                  <td>{diamonddata.fifteen_days} Diamond</td>
                </tr>
                <tr>
                  <td>Last 1 Month</td>
                  <td>{diamonddata.thirty_days} Diamond</td>
                </tr>
                <tr>
                  <td>Last 3 Month</td>
                  <td>{diamonddata.ninty_days} Diamond</td>
                </tr>
              </tbody>
            </table>

          </div>
        )

        }{
          senddiamonddata.ninty_days > 0 && interactionType === 'Send Diamond' && (
            <div id="user-interaction-details">
              <h2>User Usage</h2>

              <table>
                <thead>
                  <tr>
                    <th>Time Range</th>
                    <th>Number Of Diamond During Interaction Time</th>
                  </tr>
                </thead>
                <tbody>

                  <tr>
                    <td>Last 15 Days</td>
                    <td>{senddiamonddata.fifteen_days} Sended Diamond</td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{senddiamonddata.thirty_days} Sended Diamond</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{senddiamonddata.ninty_days}  Sended Diamond</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        }
        {
          sharedata.ninty_days > 0 && interactionType === 'Share' && (
            <div id="user-interaction-details">
              <h2>User Usage</h2>
              <table>
                <thead>
                  <tr>
                    <th>Time Range</th>
                    <th>Number Of Share During Interaction Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Last 15 Days</td>
                    <td>{sharedata.fifteen_days} Share</td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{sharedata.thirty_days} share</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{sharedata.ninty_days} share</td>
                  </tr>

                </tbody>
              </table>
            </div>
          )
        }
        {
          recivesharedata.ninty_days > 0 && interactionType === 'recive share' && (
            <div id="user-interaction-details">
              <h2>User Usage</h2>
              <table>
                <thead>
                  <tr>
                    <th>Time Range</th>
                    <th>Number Of Recived Share During Interaction Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Last 15 Days</td>
                    <td>{recivesharedata.fifteen_days} Share</td>
                  </tr>
                  <tr>
                    <td>Last 1 Month</td>
                    <td>{recivesharedata.thirty_days} share</td>
                  </tr>
                  <tr>
                    <td>Last 3 Month</td>
                    <td>{recivesharedata.ninty_days} share</td>
                  </tr>

                </tbody>
              </table>

            </div>
          )
        }
        {/* <div className="pagination">
          <button onClick={handlePrevious}>Previous</button>
          {Array.from({ length: Math.min(totalPages, 20) }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              className={currentPage === index + 1 ? "active" : ""}
            >
              {index + 1}
            </button>
          ))}
          <button onClick={handleNext}>Next</button>
        </div> */}
        <div className="pagination">
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            First
          </button>
          <button onClick={handlePrevious} disabled={currentPage === 1}>
            Previous
          </button>
          {Array.from(
            { length: pageWindow.end - pageWindow.start + 1 },
            (_, index) => pageWindow.start + index
          ).map((page) => (
            <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={currentPage === page ? 'active' : ''}
              style={{
                backgroundColor: currentPage === page ? 'red' : '',
                color: 'white',
              }}
            >
              {page}
            </button>
          ))}
          <button onClick={handleNext} disabled={currentPage === totalPages}>
            Next
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            Last
          </button>
        </div>



{/* direct page */}
<div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                    <input
                        type="number"
                        value={inputPageNumber}
                        onChange={(e) => setInputPageNumber(e.target.value)}
                        placeholder="Page No"
                        style={{
                            padding: '5px',
                            fontSize: '14px',
                            // border: '1px solid #ccc',
                            border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                            borderRadius: '4px 0 0 4px',
                            outline: 'none',
                            width: '90px'
                        }}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    <button
                        onClick={handleGoToPage}
                        style={{
                            padding: '6.5px 10px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderLeft: 'none',
                            borderRadius: '0 4px 4px 0',
                            // backgroundColor: '#007bff',
                            
                        backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                        color: 'white',
                    cursor: 'pointer',
                    outline: 'none',
                        }}

                   
                    >
                    Go
                </button>
            </div>

      </div>
      <Addfollowers

        showaddfollowerModal={ShowfollowerModal}
        closeaddfollowerModal={closeaddfollowerModal}
        handleaddfollower={handleaddfollower}
        loading={isLoading}

      />
      <Unfollow
        showunfollowModal={ShowunfollowerModal}
        closeunfollowModal={closeaddfollowerModal}
        handleunfollow={handleunfollow}
        loading={isLoading}

      />
    </IncludeSideBar>
  );
};


export default AllUser;

//  model for account deletion request

const DeleteModal = ({ onClose, onDelete }) => {
  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)'
  };

  const modalContentStyles = {
    background: 'white',
    padding: '20px',
    borderRadius: '5px',
    textAlign: 'center'
  };

  const buttonStyles = {
    margin: '5px'
  };

  const handleDelete = (days) => {
    onClose(); 
    // Close the modal before showing the confirmation dialog
    const message = days === '2min' 
    ? 'Are you sure you want to delete the account in 2 minutes?' 
    : `Are you sure you want to delete the account in ${days} days?`;
    if (window.confirm(message)) {
      onDelete(days);
    }
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2>Deletion Account Request</h2>
        <p>Select the number of days After the account is deleted:</p>
        <button style={buttonStyles} onClick={() => handleDelete('2min')}>Now</button>

        <button style={buttonStyles} onClick={() => handleDelete(1)}>1 Day</button>
        <button style={buttonStyles} onClick={() => handleDelete(7)}>7 Days</button>
        <button style={buttonStyles} onClick={() => handleDelete(15)}>15 Days</button>
        <button style={buttonStyles} onClick={() => handleDelete(30)}>30 Days</button>
        <button style={buttonStyles} onClick={onClose}>Cancel</button>
      </div>
    </div>
  );
};



//user block model
const TimePicker = ({ label, value, onChange }) => (
  <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
    <label style={{ flex: '1', textAlign: 'left' }}>{label}: </label>
    <input
      type="number"
      value={value}
      onChange={(e) => onChange(parseInt(e.target.value))}
      style={{
        width: '70px',
        padding: '10px',
        margin: '0 10px',
        borderRadius: '5px',
        border: '1px solid #ccc',
        textAlign: 'center',
      }}
      min="0"
    />
  </div>
);
const BlockModal = ({ onClose, onBlock }) => {
  const [reason, setReason] = useState('');
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [days, setDays] = useState(0);

  const handleBlock = () => {
    onClose();
    // const totalSeconds = hours * 3600 + minutes * 60 + seconds;
    const totalSeconds = days * 86400 + hours * 3600 + minutes * 60 + seconds;

    if (window.confirm(`Are you sure you want to block the user for ${hours} hours, ${minutes} minutes, and ${seconds} seconds?`)) {
      onBlock(totalSeconds, reason);
    }
  };

  const modalStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: '1000',
  };

  const modalContentStyles = {
    backgroundColor: '#fff',
    padding: '30px',
    borderRadius: '10px',
    width: '400px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  };

  const textareaStyles = {
    width: '100%',
    padding: '15px',
    margin: '15px 0',
    borderRadius: '5px',
    border: '1px solid #ccc',
    resize: 'none',
  };

  const buttonStyles = {
    backgroundColor: '#007BFF',
    color: 'white',
    padding: '12px 25px',
    margin: '10px 5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  const buttonCancelStyles = {
    backgroundColor: '#6c757d',
    color: 'white',
    padding: '12px 25px',
    margin: '10px 5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  };

  return (
    <div style={modalStyles}>
      <div style={modalContentStyles}>
        <h2 style={{ color: 'red', marginBottom: '20px' }}>User Block Request</h2>
        <p style={{ marginBottom: '20px' }}>Select the time after which the user will be blocked:</p>
        <TimePicker label="Days" value={days} onChange={setDays} />

        <TimePicker label="Hours" value={hours} onChange={setHours} />
        <TimePicker label="Minutes" value={minutes} onChange={setMinutes} />
        <TimePicker label="Seconds" value={seconds} onChange={setSeconds} />
        <textarea
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          placeholder="Reason for Blocking"
          rows="3"
          style={textareaStyles}
        />
        <button
          style={{ ...buttonStyles, backgroundColor: '#007BFF' }}
          onClick={handleBlock}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#007BFF')}
        >
          Block User
        </button>
        <button
          style={{ ...buttonCancelStyles, backgroundColor: '#6c757d' }}
          onClick={onClose}
          onMouseEnter={(e) => (e.target.style.backgroundColor = '#5a6268')}
          onMouseLeave={(e) => (e.target.style.backgroundColor = '#6c757d')}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};


//add followers

const Addfollowers = ({ showaddfollowerModal, closeaddfollowerModal, handleaddfollower, loading }) => {
  const [addfollower, setaddfollower] = useState(0);

  const handleSubmit = () => {
    handleaddfollower(addfollower);
    setaddfollower(0)
  };

  const modalStyle = {
    display: showaddfollowerModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '400px',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: '10px',
    padding: '20px'
  };

  const modalContentStyle = {
    backgroundColor: '#fefefe',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center'
  };

  const closeButtonStyle = {
    color: '#aaa',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer'
  };

  const inputStyle = {
    width: '80%',
    padding: '10px',
    margin: '10px auto',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box'
  };

  const buttonStyle = {
    padding: '8px 12px',
    margin: '10px 8px',
    background: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  return (
    <div className="modal" style={modalStyle}>
      <div className="modal-content" style={modalContentStyle}>
        <span className="close" style={closeButtonStyle} onClick={closeaddfollowerModal}>&times;</span>
        <h2>Add Follower</h2>
        {loading && (
          <div className="loader-container">
            <div className="loader"> </div>
          </div>
        )}
        <input
          type="number"
          value={addfollower}
          onChange={(e) => setaddfollower(e.target.value)}
          style={inputStyle}
        />
        <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};

//unfollow

const Unfollow = ({ showunfollowModal, closeunfollowModal, handleunfollow, loading }) => {
  const [unfollow, setunfollow] = useState(0);

  const handleSubmit = () => {
    handleunfollow(unfollow);
    setunfollow(0)
  };

  const modalStyle = {
    display: showunfollowModal ? 'block' : 'none',
    position: 'fixed',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    height: '400px',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.8)',
    borderRadius: '10px',
    padding: '20px'
  };

  const modalContentStyle = {
    backgroundColor: '#fefefe',
    width: '100%',
    height: '100%',
    borderRadius: '10px',
    padding: '20px',
    boxSizing: 'border-box',
    textAlign: 'center'
  };

  const closeButtonStyle = {
    color: '#aaa',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer'
  };

  const inputStyle = {
    width: '80%',
    padding: '10px',
    margin: '10px auto',
    borderRadius: '5px',
    border: '1px solid #ccc',
    boxSizing: 'border-box'
  };

  const buttonStyle = {
    padding: '8px 12px',
    margin: '10px 8px',
    background: 'blue',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer'
  };

  return (
    <div className="modal" style={modalStyle}>
      <div className="modal-content" style={modalContentStyle}>
        <span className="close" style={closeButtonStyle} onClick={closeunfollowModal}>&times;</span>
        <h2>UnFollow</h2>
        {loading && (
          <div className="loader-container">
            <div className="loader"> </div>
          </div>
        )}
        <input
          type="number"
          value={unfollow}
          onChange={(e) => setunfollow(e.target.value)}
          style={inputStyle}
        />
        <button style={buttonStyle} onClick={handleSubmit}>Submit</button>
      </div>
    </div>
  );
};
