import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";
const uploaddocument = async (formdata) => {
    console.log(formdata, "newAvatars");
    try {
      // const getToken = localStorage.getItem("usertoken");
      // const config = {
      //   headers: {
      //     "content-Type": "multipart/form-data",
      //   },
      // };
      const url = `${SERVER_API_URL}/admin/verify_document/uploaddocument`;
      const response = await axios.post(url, formdata);
      return response.data;
    } catch (error) {
      throw error;
    }
  };
  const getalldocument = async (page,searchTerm = '') => {
    try {
      const getToken = localStorage.getItem("token");
      const config = {
        headers: { Authorization: `Bearer ${getToken}` },
      };
      // const url = `${SERVER_API_URL}/admin/verify_document/getdocument`;
      const url = `${SERVER_API_URL}/admin/verify_document/getdocument?page=${page}&search=${searchTerm}`;

      const response = await axios.get(url,config );
      return response.data;
    } catch (error) {
      throw error;
    }
  };


  export{
    uploaddocument,
    getalldocument
  }