import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar';
import useAuth from "../../../useAuth";
import '../All/AllUser.css'

function PremiumUsers() {
  const allUserApis = require('../../../apis/users');
  const sendMoneyApis = require("../../../apis/super_admin_transaction");
  const alluserverificationrequestapi = require('../../../apis/verification_request_by_user');

  const [premiumUsers, setPremiumUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isFocused, setIsFocused] = useState(false)
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [accountTypeDropdownVisibility, setAccountTypeDropdownVisibility] = useState({});
  const [accountTypes, setAccountTypes] = useState({});
  const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
  const [inputFocus, setInputFocus] = useState(false);
  const [buttonFocus, setButtonFocus] = useState(false);

  const { user } = useAuth();
  const role = user ? user.role : null;
  console.log(role, 'rolerolerole')
  //model for send coin

  const [issendcoinModalOpen, setsendcoinModalOpen] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  //model for account type
  const [isaccountModalOpen, setIsaccountModalOpen] = useState(false);
  const [currentaccountUserId, setCurrentaccountUserId] = useState(null);


  // Model for verification link
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);
  const [verificationLink, setVerificationLink] = useState('');
  const [verificationUserId, setVerificationUserId] = useState(null);
  const [selectedVerificationType, setSelectedVerificationType] = useState('');


  const fetchUsers = async (page, searchTerm = '') => {
    try {
      setIsLoading(true)

      const response = await allUserApis.getAllPremiumUsers(page, searchTerm);
      setPremiumUsers(response.data);
      setTotalPages(response.pagination.totalPages);
      setFilteredUsers(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching users:', error);
      setIsLoading(false);
    }
  };
  useEffect(() => {

    fetchUsers(currentPage, searchTerm);
  }, [currentPage, searchTerm]);

  // console.log(currentPage,'currentPage')

  //model coin open
  const opensendcoinModal = (userId) => {
    if (role === "superadmin") {
      setsendcoinModalOpen(true);
      setCurrentUserId(userId)
    } else {
      alert("Access Denied: Only superadmins can send coins.");
    }
  };

  const closesendcoinModal = () => {
    setsendcoinModalOpen(false);
  };

  const sendCoins = async (userId, amount) => {
    try {
      const response = await sendMoneyApis.SendMoneyToUser({ userId, amount });
      console.log(response, 'response')
      if (response.data.message === 'Money sent successfully') {
        alert(`Successfully sent ${amount} coins to user with ID: ${user.id}`)
      }

      console.log(`Successfully sent ${amount} coins to user with ID: ${user.id}`);
    } catch (error) {
      console.error("Error sending coins:", error);
    }
  };

  const handleOpenVerificationModal = (userId) => {
    setVerificationUserId(userId);
    setIsVerificationModalOpen(true);
  };

  const handleGenerateVerificationLink = async () => {
    try {
      const data = {
        verificationUserId: verificationUserId
      }
      const response = await allUserApis.generateVerificationLink(data);
      setVerificationLink(response.payload);
    } catch (error) {
      console.error("Error generating verification link:", error);
    }
  };
  //scan verification link
  const handleGeneratescanVerificationLink = async () => {
    try {
      const data = {
        verificationUserId: verificationUserId
      }
      const response = await allUserApis.generateVerificationscanLink(data);
      setVerificationLink(response.payload);
    } catch (error) {
      console.error("Error generating verification link:", error);
    }
  };

  const handleVerificationRequest = async () => {
    try {
      const data = {
        userId: verificationUserId,
        verificationLink: verificationLink

      }
      console.log(data,'data for scan')
      const response = await allUserApis.UserverificationAccountrequest(data);
      if (response.success) {
        alert("Verification request processed successfully");
        handleCloseVerificationModal();
      } else {
        alert(response.message);
      }
    } catch (error) {
      console.error("Error handleVerificationRequest:", error);
    }
  };

  const handleCloseVerificationModal = () => {
    setIsVerificationModalOpen(false);
    setVerificationLink('');
    setVerificationUserId(null);
  };

  const handleGenerateVerification = () => {
    // Assuming you have different API calls for different verification types
    if (selectedVerificationType === 'link') {
      handleGenerateVerificationLink()
    } else if (selectedVerificationType === 'scan') {
      // API call for generating scan document link
      handleGeneratescanVerificationLink()
      // setVerificationLink('Generated Scan Document Link');
    }
  };
  const handleVerificationTypeChange = (event) => {
    setSelectedVerificationType(event.target.value);
  };

  //account type
  const handleOpenModal = (userId) => {
    setCurrentaccountUserId(userId);
    setIsaccountModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsaccountModalOpen(false);
    setCurrentaccountUserId(null);
  };

  const handleAccountTypeChange = async (accountType) => {
    try {
      // Assuming role is passed as a prop or accessible from the component's state
      if (role === "superadmin") {
        // Perform the account type change
        await allUserApis.changeUserAccountType(currentaccountUserId, accountType);
        // console.log(`Successfully changed account type to ${accountType} for user with ID: ${currentaccountUserId}`);
        // Update the account types state with the new account type
        setAccountTypes((prevTypes) => ({
          ...prevTypes,
          [currentaccountUserId]: accountType,
        }));
        handleCloseModal();
        alert(`Successfully change account`)

        // Fetch updated user data
        await fetchUsers();
        console.log("fetchUsers called");
      } else {
        // If the user is not a superadmin, display an error message
        alert("Access Denied: Only superadmins can change account types.");
      }
    } catch (error) {
      console.error("Error changing account type:", error);
    }
  };

  const handleDirectApprove = async (badge) => {

    const confirmed = window.confirm("Are you sure you want to approve this verification request?");
    if (confirmed) {
      const data = {
        userid: verificationUserId,
        badge: badge
      }
      //  console.log(data.userid, 'user_iduser_iduser_id');


      setIsLoading(true);
      try {
        const response = await alluserverificationrequestapi.verificationrequestapprovedbyadmin(data);
        handleCloseVerificationModal();

        if (response.success) {
          // Show success message
          alert("Verification request approved successfully");
          // Optionally, update UI or fetch updated data
        } else {
          // Show message returned from backend
          alert(response.message);
        }
      } catch (error) {
        console.error("Error handleapproved:", error);
        alert("Failed to handle approval");
      } finally {
        setIsLoading(false);
      }
    }


  };
  //account type close
  const openModal = (user) => {
    setSelectedUser(user);
    setShowModal(true);
  };

  const closeModal = () => {
    setSelectedUser(null);
    setShowModal(false);
  };
  //pagination
  const handlePrevious = () => {
    if (currentPage > 1) {
      // setCurrentPage((prevPage) => prevPage - 1);
      setCurrentPage(currentPage - 1);
    }
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      // setCurrentPage((prevPage) => prevPage + 1);
      setCurrentPage(currentPage + 1);
      // console.log('nextpage',currentPage,totalPages)
    }
  };
  const handlesearch = (e) => {
    // const value=e.target.value
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm)
    const filterdata = premiumUsers.filter((userdata) =>
      userdata.email.toLowerCase().includes(searchTerm.toLowerCase())
    )
    setFilteredUsers(filterdata)
    console.log(filterdata, 'userdatauserdata')

  }
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };
  const handleGoToPage = () => {
    const pageNumber = parseInt(inputPageNumber, 10);
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setInputPageNumber('')
    } else {
      alert(`Please enter a valid page number between 1 and ${totalPages}`);
    }
  };

  return (
    <IncludeSideBar>
      <div>
        <h1>Premium Users</h1>

        <input
          type="text"
          placeholder='Search by email ID'
          value={searchTerm}
          onChange={handlesearch}
          onFocus={handleFocus}
          onBlur={handleBlur}
          style={{
            borderWidth: isFocused ? '10px' : '1px',
            borderStyle: 'solid',
            borderColor: isFocused ? 'green' : 'white',
          }}
        />
        {isLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <div>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Email</th>
                  <th>UserName</th>

                  <th>Account Type</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredUsers.map((user) => (
                  <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.email}</td>
                    <td>{user.username}</td>

                    <td>{user.account_type}</td>
                    <td>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <button onClick={() => openModal(user)}>View Details</button>
                        {/* <button
                        style={{ marginLeft: "1%" }}
                        onClick={() => sendCoins(user.id)}>Send Coin</button> */}
                        <button
                          style={{ marginLeft: "1%" }}
                          onClick={() => opensendcoinModal(user.id)}
                        >
                          Send Coin
                        </button>

                        <button onClick={() => handleOpenModal(user.id)}>
                          Change Account Type
                        </button>
                        {/* <button onClick={() => handleverificationrequest(user.id)}>
                          Verification Request
                        </button> */}
                        <button onClick={() => handleOpenVerificationModal(user.id)}>
                          Verification Request
                        </button>
                      </div>

                    </td>
                  </tr>
                ))}

              </tbody>
            </table>
            <SendCoinsModal
              isOpen={issendcoinModalOpen}
              onClose={closesendcoinModal}
              onSend={sendCoins}
              userId={currentUserId}

            />
            <AccountTypeModal
              isOpen={isaccountModalOpen}
              onClose={handleCloseModal}
              onSave={handleAccountTypeChange}
              currentAccountType={accountTypes[currentaccountUserId] || "Select Account"}
            />
            <div style={{ justifyContent: 'center', alignItems: 'center', marginTop: '20px' }}>
              {
                filteredUsers.length === 0 &&
                <p style={{ color: '#000' }}> No Data Available</p>
              }
            </div>
            {showModal && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  zIndex: 1,
                }}
                onClick={closeModal}
              >
                <div
                  style={{
                    backgroundColor: '#fefefe',
                    padding: '20px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    maxWidth: '600px',
                    width: '100%',
                    position: 'relative',
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <span
                    style={{
                      position: 'absolute',
                      top: '10px',
                      right: '10px',
                      fontSize: '24px',
                      cursor: 'pointer',
                    }}
                    onClick={closeModal}
                  >
                    &times;
                  </span>
                  <h2>User Details</h2>
                  {selectedUser && (
                    <div>
                      <table
                        style={{
                          width: '100%',
                          borderCollapse: 'collapse',
                          marginTop: '15px',
                        }}
                      >
                        <thead>
                          <tr>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Attribute</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>ID</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{selectedUser.id}</td>
                          </tr>
                          <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Email</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{selectedUser.email}</td>
                          </tr>
                          <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>Account Type</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{selectedUser.account_type}</td>
                          </tr>
                          <tr>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>UserName</td>
                            <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>{selectedUser.username}</td>
                          </tr>
                          {/* Add more rows as needed */}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        <div style={{ display: 'flex', marginLeft: '1%', marginTop: '20px' }} className="pagination">
          <button onClick={handlePrevious} style={{ backgroundColor: 'red' }}>Previous</button>
          {
            Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => { handlePageChange(index + 1) }}
                className={currentPage === index + 1 ? 'Active' : ''}
              >
                {index + 1}
              </button>
            ))
          }

          <button onClick={handleNext} style={{ backgroundColor: 'green' }}>Next</button>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
          <input
            type="number"
            value={inputPageNumber}
            onChange={(e) => setInputPageNumber(e.target.value)}
            placeholder="Page No"
            style={{
              padding: '5px',
              fontSize: '14px',
              // border: '1px solid #ccc',
              border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

              borderRadius: '4px 0 0 4px',
              outline: 'none',
              width: '90px'
            }}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
          <button
            onClick={handleGoToPage}
            style={{
              padding: '6.5px 10px',
              fontSize: '14px',
              border: '1px solid #ccc',
              borderLeft: 'none',
              borderRadius: '0 4px 4px 0',
              // backgroundColor: '#007bff',

              backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
              color: 'white',
              cursor: 'pointer',
              outline: 'none',
            }}


          >
            Go
          </button>
        </div>
        {/* {isVerificationModalOpen && (
          <div style={modalStyle}>
            <div style={modalContentStyle}>
              <h2>Verification Request</h2>
              <div>
                <button onClick={handleGenerateVerificationLink}>Generate Verification Link</button>
                {verificationLink && <p>{verificationLink}</p>}
                <button onClick={handleGenerateVerificationLink}>Generate Verification  for Scan Document Link</button>

                <button onClick={handleVerificationRequest}>Send Verification Request</button>
              </div>
              <button onClick={handleCloseVerificationModal}>Close</button>
            </div>
          </div>
        )} */}
        {/* {isVerificationModalOpen && (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <h2>Verification Request</h2>
          <div>
            <div>
              <input
                type="radio"
                id="link"
                name="verificationType"
                value="link"
                checked={selectedVerificationType === 'link'}
                onChange={handleVerificationTypeChange}
              />
              <label htmlFor="link">Generate Verification Link</label>
            </div>
            <div>
              <input
                type="radio"
                id="scan"
                name="verificationType"
                value="scan"
                checked={selectedVerificationType === 'scan'}
                onChange={handleVerificationTypeChange}
              />
              <label htmlFor="scan">Generate Scan Document Link</label>
            </div>
            <button onClick={handleGenerateVerification}>
              Generate Link
            </button>
            {verificationLink && <p>{verificationLink}</p>}
            <button onClick={handleVerificationRequest}>
              Send Verification Request
            </button>
          </div>
          <button onClick={handleCloseVerificationModal}>Close</button>
        </div>
      </div>
    )} */}
        <VerificationModal
          isVerificationModalOpen={isVerificationModalOpen}
          selectedVerificationType={selectedVerificationType}
          handleVerificationTypeChange={handleVerificationTypeChange}
          handleGenerateVerification={handleGenerateVerification}
          verificationLink={verificationLink}
          handleVerificationRequest={handleVerificationRequest}
          handleCloseVerificationModal={handleCloseVerificationModal}
          handleDirectApprove={handleDirectApprove}
        />

      </div>
    </IncludeSideBar>
  );
}

export default PremiumUsers;


//send coin model

const SendCoinsModal = ({ isOpen, onClose, onSend, userId }) => {
  const [amount, setAmount] = useState('');

  const handleSend = () => {
    if (amount.trim() !== "") {
      onSend(userId, amount);
      setAmount('');
      onClose();
    } else {
      alert("Please enter a valid amount.");
    }
  };

  if (!isOpen) return null;

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  };

  const modalContentStyle = {
    background: 'white',
    padding: '30px',
    borderRadius: '8px',
    width: '300px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    textAlign: 'center',
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    margin: '10px 0',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    margin: '10px',
    borderRadius: '4px',
    border: 'none',
    cursor: 'pointer',
    fontSize: '16px',
  };

  const sendButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#4CAF50',
    color: 'white',
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#f44336',
    color: 'white',
  };

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <h2>Send Coins</h2>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Enter amount"
          style={inputStyle}
        />
        <div>
          <button onClick={handleSend} style={sendButtonStyle}>Send</button>
          <button onClick={onClose} style={cancelButtonStyle}>Cancel</button>
        </div>
      </div>
    </div>
  );
};


//account type model
const AccountTypeModal = ({ isOpen, onClose, onSave, currentAccountType }) => {
  const [selectedAccountType, setSelectedAccountType] = React.useState(currentAccountType);

  const handleSave = () => {
    onSave(selectedAccountType);
  };

  const modalStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 999
  };

  const modalContentStyle = {
    background: 'white',
    padding: '20px',
    borderRadius: '8px',
    width: '300px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    textAlign: 'center',
    position: 'relative'
  };

  const selectStyle = {
    width: '100%',
    padding: '10px',
    marginBottom: '20px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '16px',
    boxSizing: 'border-box',
    outline: 'none'
  };

  const buttonStyle = {
    cursor: 'pointer',
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    fontSize: '16px',
    transition: 'background-color 0.3s ease',
    marginRight: '10px'
  };

  const cancelButtonStyle = {
    ...buttonStyle,
    backgroundColor: '#6c757d'
  };

  return isOpen ? (
    <div style={modalStyle}>
      <div style={modalContentStyle}>
        <h2 style={{ marginBottom: '20px' }}>Change Account Type</h2>
        <select
          value={selectedAccountType}
          onChange={(e) => setSelectedAccountType(e.target.value)}
          style={selectStyle}
        >
          <option value="Select Account">Select Account Type</option>
          <option value="basic">Basic</option>
          <option value="business">Business</option>
        </select>
        <button onClick={handleSave} style={buttonStyle}>Save</button>
        <button onClick={onClose} style={cancelButtonStyle}>Cancel</button>
      </div>
    </div>
  ) : null;
};



//verification model

const VerificationModal = ({
  isVerificationModalOpen,
  selectedVerificationType,
  handleVerificationTypeChange,
  handleGenerateVerification,
  verificationLink,
  handleVerificationRequest,
  handleCloseVerificationModal,
  handleDirectApprove
}) => {
  const [selectedBadge, setSelectedBadge] = useState('');

  const modalStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
  };

  const modalContentStyle = {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
    width: '500px',
    textAlign: 'center',
    boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
  };

  const dropdownStyle = {
    padding: '10px',
    width: '100%',
    borderRadius: '4px',
    border: '1px solid #ccc',
    margin: '10px 0',
  };

  const buttonStyle = {
    padding: '10px 20px',
    borderRadius: '4px',
    border: 'none',
    backgroundColor: '#007bff',
    color: '#fff',
    cursor: 'pointer',
    marginTop: '10px',
    
  };
  const buttongenwrap={
    marginRight:'5px'
  }
const buttonwraper={
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
  
}
  const badgeOptions = [
    'Simple',
    'Verified Top 1',
    'Business Account',
    'Premium Users',
    'Agent'
  ];

  const handleBadgeChange = (e) => {
    setSelectedBadge(e.target.value);
  };

  const handleDirectApproveClick = () => {
    handleDirectApprove(selectedBadge);
  };

  return (
    isVerificationModalOpen && (
      <div style={modalStyle}>
        <div style={modalContentStyle}>
          <h2>Verification Request</h2>
          <div>
            <div>
              <input
                type="radio"
                id="link"
                name="verificationType"
                value="link"
                checked={selectedVerificationType === 'link'}
                onChange={handleVerificationTypeChange}
              />
              <label htmlFor="link">Generate Verification Link</label>
            </div>
            <div>
              <input
                type="radio"
                id="scan"
                name="verificationType"
                value="scan"
                checked={selectedVerificationType === 'scan'}
                onChange={handleVerificationTypeChange}
              />
              <label htmlFor="scan">Generate Scan Document Link</label>
            </div>
            <div style={buttonwraper}>
            <div style={buttongenwrap}>
           <button onClick={handleGenerateVerification} style={buttonStyle}>
              Generate Link
            </button>
           </div>


            <button onClick={handleVerificationRequest} style={buttonStyle}>
              Send Verification Request
            </button>
            </div>
            {verificationLink && <p>{verificationLink}</p>}

          </div>
          <div style={{ marginTop: '20px' }}>
            <h3>Select Badge for Direct Approval</h3>
            <select
              value={selectedBadge}
              onChange={handleBadgeChange}
              style={dropdownStyle}
            >
              <option value="" disabled>Select a badge</option>
              {badgeOptions.map((badge, index) => (
                <option key={index} value={badge}>
                  {badge}
                </option>
              ))}
            </select>
            <button onClick={handleDirectApproveClick} style={buttonStyle} disabled={!selectedBadge}>
              Direct Approve
            </button>
          </div>
          <button onClick={handleCloseVerificationModal} style={{ ...buttonStyle, backgroundColor: '#6c757d' }}>
            Close
          </button>
        </div>
      </div>
    )
  );
};
