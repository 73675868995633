// import { useEffect } from 'react';
// import { useLocation } from 'react-router-dom';

// const useSavePageURL = () => {
//   const location = useLocation();

//   useEffect(() => {
//     window.localStorage.setItem('currentPath', location.pathname);
//   }, [location]);
// };

// export default useSavePageURL;


// useSavePageURL.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useSavePageURL = (shouldExecute) => {
  const location = useLocation();

  useEffect(() => {
    if (shouldExecute) {
      window.localStorage.setItem('currentPath', location.pathname);
    }
  }, [location, shouldExecute]);
};

export default useSavePageURL;
