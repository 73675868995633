
import { SERVER_API_URL, SERVER_DOMAIN } from "../constants/constants";
import axios from "axios";


const getAllprofilerankedvideo = async (page,searchTerm='',timePeriod = '') => {
 const getToken = localStorage.getItem("token");
 console.log('hello ranked')
 const config = {
   headers: { Authorization: `Bearer ${getToken}` },
 };
//  const url = `${SERVER_API_URL}/admin/ranked_video/getprofilerankedvideo`;
   // const url = `${SERVER_API_URL}/admin/ranked_video/getrankedvideo?page=${page}&pageSize=${pageSize}`;
  //  const url = `${SERVER_API_URL}/admin/ranked_video/getprofilerankedvideo?page=${page}&search=${searchTerm}`;
  const url = `${SERVER_API_URL}/admin/ranked_video/getprofilerankedvideo?page=${page}&search=${searchTerm}&timePeriod=${timePeriod}`;


 const result = await axios.get(url, config);
 return result.data;
};


const deleteprofilerankedvideo=async(id)=>{
  const getToken = localStorage.getItem("token");
  console.log('hello ranked')
  const config = {
    headers: { Authorization: `Bearer ${getToken}` },
  };
  const url = `${SERVER_API_URL}/admin/ranked_video/deleteprofileRankedVideo/${id}`;
  const result = await axios.delete(url, config);
  return result.data;
 
}













export { getAllprofilerankedvideo,deleteprofilerankedvideo};
