import React, { useEffect, useState } from 'react';
import IncludeSideBar from '../../../Components/Sidebar/IncludeSideBar'
import '../All/AllUser.css'

function BlockedUsers() {
    const allUserApis = require("../../../apis/users");
    const [users, setUsers] = useState([]);
    const [blockuser, setblockuser] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [searchTerm, setSearchTerm] = useState('')
    const [inputPageNumber, setInputPageNumber] = useState(''); // State to hold the input page number
    const [inputFocus, setInputFocus] = useState(false);
    const [buttonFocus, setButtonFocus] = useState(false);
    // const getBlockedUsers = async () => {
    //     try {
    //         const response = await allUserApis.getBlockUsers();
    //         console.log(response.data, "response from blocked users");
    //         setUsers(response.data);
    //     } catch (error) {
    //         console.error("Error fetching users:", error);
    //     }
    // };

    // useEffect(() => {
    //     getBlockedUsers();
    // }, []);

    const getBlockedUsersrequest = async (page, searchTerm = '') => {
        try {
            const response = await allUserApis.getBlockUsersrequest(page, searchTerm);
            console.log(response.pagination.totalPages, "response from blocked request users");
            const usersWithRemainingTime = response.users.map(user => ({
                ...user,
                remainingTime: new Date(user.blockAt) - Date.now()
            }));
            setblockuser(usersWithRemainingTime);
            setTotalPages(response.pagination.totalPages);

        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    // useEffect(() => {
    //     getBlockedUsersrequest();
    // }, []);

    useEffect(() => {
        getBlockedUsersrequest(currentPage, searchTerm);
    }, [currentPage, searchTerm]);

    const handleSearch = (searchValue) => {
        setSearchTerm(searchValue);
    };


    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            // setCurrentPage((prevPage) => prevPage + 1);
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 1) {
            // setCurrentPage((prevPage) => prevPage - 1);
            setCurrentPage(currentPage - 1);
        }
    };
    const handleGoToPage = () => {
        const pageNumber = parseInt(inputPageNumber, 10);
        if (pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
            setInputPageNumber('')
        } else {
            alert(`Please enter a valid page number between 1 and ${totalPages}`);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setblockuser(users => users.map(user => {
                if (user.remainingTime <= 0) {
                    return { ...user, remainingTime: 0 }; // Ensure time doesn't go negative
                }
                return {
                    ...user,
                    remainingTime: user.remainingTime - 1000
                };
            }));
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    // const formatRemainingTime = (milliseconds) => {
    //     const totalSeconds = Math.floor(milliseconds / 1000);
    //     const hours = Math.floor(totalSeconds / 3600);
    //     const minutes = Math.floor((totalSeconds % 3600) / 60);
    //     const seconds = totalSeconds % 60;

    //     return `${hours}h ${minutes}m ${seconds}s`;
    // };
    const formatRemainingTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const totalHours = Math.floor(totalSeconds / 3600);
        const days = Math.floor(totalHours / 24);
        const hours = totalHours % 24;
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;
    
        return `${days}D ${hours}H ${minutes}M ${seconds}S`;
    };
    

    const handleStatusChange = async (userId) => {
        // console.log(userId)
        try {
            // Make an API call to update the user's status to 1
            const response = await allUserApis.updateUserStatus(userId, 1);

            // Assuming the API call is successful, update the local state
            if (response.status === 200) {
                const updatedUsers = blockuser.map((user) =>
                    user.user_id === userId ? { ...user, status: 1 } : user
                );
                // setUsers(updatedUsers);
                setblockuser(updatedUsers)
                // getBlockedUsers();
                getBlockedUsersrequest();


            } else {
                console.error("Failed to update status");
            }
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    return (
        <IncludeSideBar>
            <div>
                <p>Blocked User Details:</p>
                <input
                    type="text"
                    placeholder="Search by username and Email ID"
                    value={searchTerm}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                <table>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Username</th>
                            <th>Email</th>
                            <th>Account Type</th>
                            <th>Count Down</th>
                            <th>Action</th> {/* New column for the Status button */}
                        </tr>
                    </thead>
                    <tbody>

                        {blockuser.map((user, index) => (
                            <tr key={index}>
                                <td>{user.user_id}</td>
                                <td>{user.username}</td>
                                <td>{user.email}</td>
                                <td>{user.account_type}</td>
                                <th>{formatRemainingTime(user.remainingTime)}</th>

                                <td>
                                    <button onClick={() => handleStatusChange(user.user_id)}>
                                        Activate User
                                    </button>
                                </td>
                            </tr>
                        ))}

                    </tbody>
                </table>
                <div className="pagination">
                    <button onClick={handlePrevious} style={{ backgroundColor: 'red' }}>Previous</button>

                    {Array.from({ length: Math.min(totalPages, 20) }, (_, index) => (
                        <button
                            key={index + 1}
                            onClick={() => handlePageChange(index + 1)}
                            className={currentPage === index + 1 ? "active" : ""}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button onClick={handleNext} style={{ backgroundColor: 'green' }}>Next</button>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginLeft: '10px' }}>
                    <input
                        type="number"
                        value={inputPageNumber}
                        onChange={(e) => setInputPageNumber(e.target.value)}
                        placeholder="Page No"
                        style={{
                            padding: '5px',
                            fontSize: '14px',
                            // border: '1px solid #ccc',
                            border: `2px solid ${inputFocus ? 'green' : '#ccc'}`,

                            borderRadius: '4px 0 0 4px',
                            outline: 'none',
                            width: '90px'
                        }}
                        onFocus={() => setInputFocus(true)}
                        onBlur={() => setInputFocus(false)}
                    />
                    <button
                        onClick={handleGoToPage}
                        style={{
                            padding: '6.5px 10px',
                            fontSize: '14px',
                            border: '1px solid #ccc',
                            borderLeft: 'none',
                            borderRadius: '0 4px 4px 0',
                            // backgroundColor: '#007bff',

                            backgroundColor: `${buttonFocus ? 'green' : '#007bff'}`,
                            color: 'white',
                            cursor: 'pointer',
                            outline: 'none',
                        }}


                    >
                        Go
                    </button>
                </div>

            </div>
        </IncludeSideBar>
    );
}

export default BlockedUsers;
